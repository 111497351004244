import {jwtDecode} from "jwt-decode";

function Auth(role) {

    let token  = localStorage.getItem("token");
    if (token === null || token === 'null' || token === '') {
        return "/login";
    } else {
        let user = jwtDecode(token);
        if (user.expiration < Date.now()) {
            return "/login";
        }
        if (role != null && !user.authorities === 'ROLE_ADMIN' && !user.authorities.includes(role)) {
            return "/";
        } else return "";
    }
}
export default Auth;