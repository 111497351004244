import React, {useEffect, useState} from "react";
import M from "materialize-css";
import UserListElement from "./UserListElement";
import {Navigate} from "react-router-dom";
import Auth from "../../common/Auth";
import Pagination from '@mui/material/Pagination';
import {TablePagination} from "@mui/material";

const UsersList = (props) => {
    const [users, setusers] = useState([]);
    const [searchNameValue, setSearchNameValue] = useState('');
    const [searchLoginValue, setSearchLoginValue] = useState('');
    const [pageNumber, setPageNumber] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [pages, setPages] = useState([]);
    let queryParams = {
        name: '',
        login: ''
    };

    const removeUser = (user) => {
        setusers(users.filter(item => item.id !== user.id));
    }

    useEffect(() => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        };
        fetch(process.env.REACT_APP_API_URL + '/dedomena-user-management/users?page=0&size=100', requestOptions)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                } else {
                    setusers(data);
                }

            })
            .catch(error => {
                console.log(error);
                M.toast({
                    html: '<n:i class="material-icons">error</n:i> Cannot retrieve users list. Try again later.',
                    classes: 'red lighten-1 white-text'
                });
            });
    }, []);

    const handlePageEvent = (page) => {
        setPageNumber(page-1);
        doSearch(page-1);
    }

    function doSearch(page) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        };

        function jsonToUrlParams(queryParams) {
            let result = '';
            if (queryParams.name !== '')
                result += '&name=' + queryParams.name;
            if (queryParams.login !== '')
                result += '&login=' + queryParams.login;
            return result;
        }

        fetch(process.env.REACT_APP_API_URL + '/dedomena-user-management/users/search?page=' + (page != null ? page : pageNumber) + '&size=' + pageSize + jsonToUrlParams(queryParams), requestOptions)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                } else {
                    setusers(data.content);
                    setTotalPages(data.totalPages);
                    let arrayPages = new Array(data.totalPages);
                    for (let i = 0; i < data.totalPages; i++) {
                        arrayPages[i] = i+1;
                    }
                    setPages(arrayPages);
                }

            })
            .catch(error => {
                console.log(error);
                M.toast({
                    html: '<n:i class="material-icons">error</n:i> Cannot retrieve users list. Try again later.',
                    classes: 'red lighten-1 white-text'
                });
            });
    }

    const handleSearch = (item, value) => {

        if (item === 'name') {
            queryParams.name = value;
            queryParams.login = searchLoginValue;
        } else if (item === 'login') {
            queryParams.login = value;
            queryParams.name = searchNameValue;
        } else {
            queryParams.name = searchNameValue;
            queryParams.login = searchLoginValue;
        }
        doSearch();
    };

    // function Pages() {
    //     let result = null;
    //     for (let i = 0; i < totalPages; i++) {
    //         result += <li key={i} className="waves-effect" > {i} </li>;
    //     }
    //     return result;
    // }
    //
    // function Pagination () {
    //     if (pages.length > 0) {
    //         return (
    //             <ul className="pagination">
    //                 <li className="disabled"><a href="#!"><i className="material-icons">chevron_left</i></a></li>
    //                 <Pages/>
    //                 <li className="waves-effect"><a href="#!"><i className="material-icons">chevron_right</i></a></li>
    //             </ul>);
    //     } else return '';
    // }

    let renderedPage = Auth("ROLE_ADMIN");
    if (renderedPage !== "") {
        return <Navigate replace to={renderedPage} />;
    } else {
        return (
            <div>
                <div className={"row"}>
                    <div className={"col s12"}>
                        <table className={"responsive-table"}>
                            <thead>
                            <tr>
                                <th>
                                    Name
                                    <input
                                        type="text"
                                        value={searchNameValue}
                                        onChange={(event) => {
                                            setSearchNameValue(event.target.value);
                                            handleSearch('name', event.target.value);
                                        }}
                                        placeholder='Type an username to search'
                                    />
                                </th>
                                <th>
                                    Email
                                    <input
                                        type="text"
                                        value={searchLoginValue}
                                        onChange={(event) => {
                                            setSearchLoginValue(event.target.value);
                                            handleSearch('login', event.target.value);
                                        }}
                                        placeholder='Type an e-mail to search'
                                    />
                                </th>
                                <th>Phone</th>
                                <th>Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {users.map(item => <UserListElement key={item.id} item={item} removeUser={removeUser}/>)}
                            </tbody>
                        </table>
                    </div>
                </div>
                <Pagination className={'white'}
                            count={totalPages}
                            onChange={(event, value) => handlePageEvent(value)}
                />
            </div>
        )
    }
}
export default UsersList;