import React, {useEffect, useRef, useState} from 'react';
import Menu from "../menu-component/Menu";
import {Navigate} from "react-router-dom";
import {jwtDecode} from "jwt-decode";
import {GeoJSON, MapContainer, TileLayer, useMap, useMapEvents} from "react-leaflet"
import 'leaflet/dist/leaflet.css';
import './Navigator.css';
import M from "materialize-css";
import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';
import wNumb from 'wnumb';
import BetterWMS from "./BetterWMS";
import ReactSearchBox from "react-search-box";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import "leaflet-spin";
import Auth from "../../common/Auth";
import "materialize-css/dist/css/materialize.min.css";
import {Box, Tab, Tabs} from "@mui/material";

const Navigator = () => {
    const [payload, setPayload] = useState(localStorage.getItem("token"));
    const [datasets, setDatasets] = useState([]);
    const [gemeindes, setGemeindes] = useState([]);
    const [ selectedDataset, setSelectedDataset] = useState('CH-BL');
    const [ selectedGemeinde, setSelectedGemeinde] = useState();
    const [ selectedFlaeche, setSelectedFlaeche] = useState([]);
    const [ selectedFreeFlaeche, setSelectedFreeFlaeche] = useState([]);
    const [ minGeschossPotential, setMinGeschossPotential] = useState(-10);
    const [ maxFloorPotential, setMaxFloorPotential] = useState(4);
    const [ maxReserveM2, setMaxReserveM2] = useState(50000);
    const [ minReserveM2Query, setMinReserveM2Query] = useState(-10);
    const [ maxReserveM2Query, setMaxReserveM2Query] = useState(50000);
    const [ maxPotentialM2, setMaxPotentialM2] = useState(5.0);
    const [ minPotentialM2Query, setMinPotentialM2Query] = useState(0);
    const [ maxPotentialM2Query, setMaxPotentialM2Query] = useState(5.0);
    const [ addresseData, setAddresseData] = useState([]);
    const [ addresse, setAddresse] = useState();
    const [ addresseFilter, setAddresseFilter] = useState();
    const [center, setCenter] = useState([47.3382723372246, 7.33631501368192]);
    const [ bounds, setBounds] = useState([[47.3382723372246, 7.33631501368192],[47.56436509534766, 7.945061760112403]]);
    const [lockMap, setLockMap] = useState(false);
    const [previousLockMap, setPreviousLockMap] = useState(false);
    const [buildingType, setBuildingType] = useState();
    const [searchOffset, setSearchOffset] = useState(0);
    const [sortingProperty, setSortingProperty] = useState({property: "reserveM2Pc", direction: "DESC"});
    const [tableData, setTableData] = useState();
    const [currentView, setCurrentView] = useState("map");

    let sliderGeschossPotential;
    let sliderReserveM2;
    let sliderPotentialM2;

    function lockMapChanged(checked) {
        setLockMap(checked);
    }

    useEffect(() => {
        onFilterChanged()
    }, [selectedDataset, selectedFlaeche, addresse, minPotentialM2Query, maxPotentialM2Query, minReserveM2Query, maxReserveM2Query, minGeschossPotential, maxFloorPotential, buildingType, selectedGemeinde, searchOffset, sortingProperty]);

    function onFilterChanged() {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        };
        var params = '?offset=' + searchOffset + '&dataset=' + selectedDataset + '&sortProperty=' + sortingProperty.property + '&sortOrder=' + sortingProperty.direction;
        if (addresse != null)
            params += '&addresse=' + addresse;
        if (minPotentialM2Query != null)
            params += '&minPotentialM2Pc=' + minPotentialM2Query;
        if (maxPotentialM2Query != null)
            params += '&maxPotentialM2Pc=' + maxPotentialM2Query;
        if (minReserveM2Query != null)
            params += '&minReserveM2=' + minReserveM2Query;
        if (maxReserveM2Query != null)
            params += '&maxReserveM2=' + maxReserveM2Query;
        if (minGeschossPotential != null)
            params += '&minPotentialM2=' + minGeschossPotential;
        if (maxFloorPotential != null)
            params += '&maxPotentialM2=' + maxFloorPotential;
        if (buildingType != null)
            params += '&buildingType=' + buildingType;
        if (selectedGemeinde != null && selectedGemeinde !== '')
            params += '&gemeinde=' + selectedGemeinde;
        fetch(process.env.REACT_APP_API_URL + '/dedomena-data-management/navigator-table/search' + params, requestOptions)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                } else {
                    setTableData(data);
                }

            })
            .catch(error => {
                console.log(error);
                M.toast({
                    html: '<n:i class="material-icons">error</n:i> Cannot retrieve plot data. Try again later.',
                    classes: 'red lighten-1 white-text'
                });
            });
    }

    function createSliderPotentialM2(pc) {
        sliderPotentialM2 = document.getElementById('max-potential-m2-slider');
        destroyExistingSlider(sliderPotentialM2);
        noUiSlider.create(sliderPotentialM2, {
            start: 0,
            connect: "upper",
            tooltips: true,
            range: {
                'min': 0,
                'max': (pc+0.01)*100
            },
            format: wNumb({
                decimals: 2
            })
        });
        sliderPotentialM2.noUiSlider.on('set', function (values, handle) {
            setMinPotentialM2Query(values[0]/100);
            // setMaxPotentialM2Query(values[1]+0.01);
        });
    }

    function createSliderReserveM2(m2) {
        sliderReserveM2 = document.getElementById('max-reserve-slider');
        destroyExistingSlider(sliderReserveM2);
        noUiSlider.create(sliderReserveM2, {
            start: [-1000],
            connect: "upper",
            tooltips: true,
            range: {
                'min': -1000,
                'max': (m2+0.01)*100
            },
            format: wNumb({
                decimals: 2
            })
        });
        sliderReserveM2.noUiSlider.on('set', function (values, handle) {
            setMinReserveM2Query(values[0]/100);
            // setMaxReserveM2Query(values[1] + 0.01);
        });
    }

    const addresseSelected = (e) => {
        setAddresse(e.item.value);
        setAddresseFilter(e.item.key.split(",")[0]);
        fetchBbox(selectedDataset, null, e.item.key.split(",")[0])
    }

    const deleteAddress =() => {
      setAddresse('');
      setAddresseFilter(null);
      fetchBbox(selectedDataset, selectedGemeinde, null);
    }

    const deleteFilters =() => {
        deleteAddress();
        flaecheChanged(true, 'Alle');
        freeFlaecheChanged(true, 'Alle');
        buildingTypeChanged(true, 'Alle');
        setSelectedFlaeche(['Alle']);
        setSelectedFreeFlaeche(['Alle']);
        setBuildingType('Alle');
        createSliderPotentialM2(maxPotentialM2);
        createSliderReserveM2(maxReserveM2);
        createSliderGeschossPotential(maxFloorPotential);
        setMinPotentialM2Query(0);
        setMinReserveM2Query(-10);
        setMinGeschossPotential(-10);
        datasetChanged('CH-BL');
    }

    const addresseChanged = (addresse) => {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        };
        var params = '?dataset=' + selectedDataset + '&addresse=' + addresse;
        if (selectedGemeinde != null && selectedGemeinde !== '')
            params += '&gemeinde=' + selectedGemeinde;
        fetch(process.env.REACT_APP_API_URL + '/dedomena-data-management/dataset/addresses' + params, requestOptions)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                } else {
                    var addressMap = new Array(data.length);
                    for (var i = 0; i < data.length; i++) {
                        addressMap[i]= {key: (data[i].egrisEgri + "," + data[i].lat + "," + data[i].lon), value: data[i].addressText};
                    }
                    setAddresseData(addressMap);
                }

            })
            .catch(error => {
                console.log(error);
                M.toast({
                    html: '<n:i class="material-icons">error</n:i> Cannot retrieve addresses. Try again later.',
                    classes: 'red lighten-1 white-text'
                });
            });
    }

    function fetchBbox(dataSet, gemeinde, egris) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        };
        let params = '?dataset=' + dataSet;
        if (gemeinde != null && gemeinde.length > 0)
            params += '&gemeinde=' + gemeinde;
        if (egris != null && egris.length > 0)
            params += '&egris=' + egris;
        fetch(process.env.REACT_APP_API_URL + '/dedomena-data-management/gemeinde/bbox' + params, requestOptions)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                } else {
                    setBounds(data);
                    setCenter(data[0]);
                }

            })
            .catch(error => {
                console.log(error);
                M.toast({
                    html: '<n:i class="material-icons">error</n:i> Cannot retrieve data for these filters. Try again later.',
                    classes: 'red lighten-1 white-text'
                });
            });
    }

    const datasetChanged = (dataSet) => {
        setSelectedDataset(dataSet);
        fetchGemeindes(dataSet);
        gemeindeChanged('Alle');
        fetchMaxReserveM2(dataSet);
        fetchMaxPotentialM2(dataSet);
        fetchBbox(dataSet, null);
    }

    function fetchMaxPotentialM2(dataSet) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        };
        fetch(process.env.REACT_APP_API_URL + '/dedomena-data-management/gemeinde/maxPotentialM2?dataset=' + dataSet, requestOptions)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                } else {
                    setMaxPotentialM2(data);
                    setMaxPotentialM2Query(data);
                    createSliderPotentialM2(data);
                }

            })
            .catch(error => {
                console.log(error);
                M.toast({
                    html: '<n:i class="material-icons">error</n:i> Cannot retrieve max reserve percentage. Try again later.',
                    classes: 'red lighten-1 white-text'
                });
            });
    }

    function fetchMaxReserveM2(dataSet) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        };
        fetch(process.env.REACT_APP_API_URL + '/dedomena-data-management/gemeinde/maxReserveM2?dataset=' + dataSet, requestOptions)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                } else {
                    setMaxReserveM2(data);
                    setMaxReserveM2Query(data);
                    createSliderReserveM2(data);
                }

            })
            .catch(error => {
                console.log(error);
                M.toast({
                    html: '<n:i class="material-icons">error</n:i> Cannot retrieve max reserve m2. Try again later.',
                    classes: 'red lighten-1 white-text'
                });
            });
    }

    function fetchGemeindes(dataSet) {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        };
        fetch(process.env.REACT_APP_API_URL + '/dedomena-data-management/gemeinde?dataset=' + dataSet, requestOptions)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                } else {
                    setGemeindes(data);
                    // var elems = document.querySelectorAll('#gemeinde')[0];
                    // console.log(elems);
                    // M.FormSelect.init(elems, data);
                }

            })
            .catch(error => {
                console.log(error);
                M.toast({
                    html: '<n:i class="material-icons">error</n:i> Cannot retrieve dataset list. Try again later.',
                    classes: 'red lighten-1 white-text'
                });
            });
    }

    const gemeindeChanged = (gemeindeVal) => {
        if (gemeindeVal === 'Alle') {
            setSelectedGemeinde('');
            fetchBbox(selectedDataset, null);
        }
        else {
            setSelectedGemeinde(gemeindeVal);
            fetchBbox(selectedDataset, gemeindeVal);
        }
    }

    const flaecheChanged = (checked, flaecheVal) => {
        if (checked) {
            if (flaecheVal === 'Alle') {
                setSelectedFlaeche([]);
            }
            else
                setSelectedFlaeche([flaecheVal]);
        }
        else {
            if (flaecheVal === 'Alle')
                setSelectedFlaeche([]);
            else {
                const oldFlaeches = selectedFlaeche.filter((flaeche) => flaeche !== flaecheVal);
                setSelectedFlaeche(oldFlaeches);
            }
        }
    }

    const freeFlaecheChanged = (checked, freeFlaecheVal) => {
        if (checked) {
            if (freeFlaecheVal === 'Alle') {
                setSelectedFreeFlaeche([]);
            }
            else
                setSelectedFreeFlaeche([freeFlaecheVal]);
        }
        else {
            if (freeFlaecheVal === 'Alle')
                setSelectedFreeFlaeche([]);
            else {
                const oldFreeFlaeches = selectedFreeFlaeche.filter((flaeche) => flaeche !== freeFlaecheVal);
                setSelectedFreeFlaeche(oldFreeFlaeches);
            }
        }
    }

    const buildingTypeChanged = (checked, buildingTypeVal) => {
        if (buildingTypeVal === 'Alle') {
            setBuildingType(null);
        }
        else
            setBuildingType(buildingTypeVal);
    }

    function createSliderGeschossPotential(maxGeschossPotential) {
        sliderGeschossPotential = document.getElementById('floor-potential-slider');
        destroyExistingSlider(sliderGeschossPotential);
        noUiSlider.create(sliderGeschossPotential, {
            start: [-10],
            connect: "upper",
            tooltips: true,
            range: {
                'min': -10,
                'max': maxGeschossPotential
            },
            format: wNumb({
                decimals: 0
            })
        });
        sliderGeschossPotential.noUiSlider.on('set', function (values, handle) {
            setMinGeschossPotential(values[0]);
            // setMaxFloorPotential(values[1]);
        });
    }

    useEffect(() => {
        const L = require("leaflet");

        delete L.Icon.Default.prototype._getIconUrl;

        L.Icon.Default.mergeOptions({
            iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
            iconUrl: require("leaflet/dist/images/marker-icon.png"),
            shadowUrl: require("leaflet/dist/images/marker-shadow.png")
        });

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        };
        fetch(process.env.REACT_APP_API_URL + '/dedomena-data-management/dataset', requestOptions)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                } else {
                    setDatasets(data);
                    fetchGemeindes(data[0].dataset);
                    fetchMaxReserveM2(data[0].dataset);
                    fetchMaxPotentialM2(data[0].dataset);
                    var elems = document.querySelectorAll('#dataset');
                    M.FormSelect.init(elems, data);
                }

            })
            .catch(error => {
                console.log(error);
                M.toast({
                    html: '<n:i class="material-icons">error</n:i> Cannot retrieve dataset list. Try again later.',
                    classes: 'red lighten-1 white-text'
                });
            });

        createSliderGeschossPotential(maxFloorPotential);
        createSliderReserveM2(maxReserveM2);
        createSliderPotentialM2(maxPotentialM2);

        M.AutoInit(document.getElementById('filtersForm'));
        window.M.updateTextFields();
    }, []);

    function destroyExistingSlider(slider){
        if(slider && slider.noUiSlider){
            slider.noUiSlider.destroy();
        }
    }

    function cqlFormatStringArray(array) {
        var result = "";
        for (var i = 0; i < array.length; i++) {
            result += "'" + array[i] + "',";
        }
        return result.substring(0, result.length -1);
    }

    function createFlaecheCQLQuery(selectedFlaeche) {
        var result = "";
        if (selectedFlaeche.length > 0 && selectedFlaeche[0] !== 'Alle') {
            result += "(";
            for (var i = 0; i < selectedFlaeche.length; i++) {
                result += ((selectedFlaeche[i] === '0' ? "(flaeche >=0 AND flaeche <1000) OR " : ""));
                result += ((selectedFlaeche[i] === '1' ? "(flaeche >=1000 AND flaeche <5000) OR " : ""));
                result += ((selectedFlaeche[i] === '2' ? "(flaeche >=5000 AND flaeche <10000) OR " : ""));
                result += ((selectedFlaeche[i] === '3' ? "(flaeche >=10000 AND flaeche <50000) OR " : ""));
                result += ((selectedFlaeche[i] === '4' ? "(flaeche >=50000) OR " : ""));
            }
            result = result.substring(0, result.lastIndexOf('OR'));
            result += ") AND ";
        }
        return result;
    }

    function createAddresssFilter(address) {
        var result = "";
        if (address != null && address.length > 0) {
            result += "(egris_egri = '" + address;
            result += "') AND ";
        }
        return result;
    }

    function createFreeFlaecheCQLQuery(selectedFreeFlaeche) {
        var result = "";
        if (selectedFreeFlaeche.length > 0 && selectedFreeFlaeche[0] !== 'Alle') {
            result += "(";
            for (var i = 0; i < selectedFreeFlaeche.length; i++) {
                result += ((selectedFreeFlaeche[i] === '0' ? "(free_area_pc >=0 AND free_area_pc <0.2) OR " : ""));
                result += ((selectedFreeFlaeche[i] === '1' ? "(free_area_pc >=0.2 AND free_area_pc <0.5) OR " : ""));
                result += ((selectedFreeFlaeche[i] === '2' ? "(free_area_pc >=0.5 AND free_area_pc <0.8) OR " : ""));
                result += ((selectedFreeFlaeche[i] === '3' ? "(free_area_pc >=0.8 AND free_area_pc <0.85) OR " : ""));
                result += ((selectedFreeFlaeche[i] === '4' ? "(free_area_pc >=0.85) OR " : ""));
            }
            result = result.substring(0, result.lastIndexOf('OR'));
            result += ") AND ";
        }
        return result;
    }
    function calculateKanton(dataset) {
        switch(dataset) {
            case 'CH-BL': return 'Basel-Landschaft';
            case 'CH-BE': return 'Bern';
            case 'CH-BS': return 'Basel-Stadt';
            case 'CH-SO': return 'Solothurn';
            case 'CH-ZH': return 'Zürich';
            default: return dataset;
        }
    }

    function calculateGebaudeTypes(types) {
        let result = "";
        for (var i = 0; i < types.length; i++) {
            result += calculateGebaudeTyp(types[i].trim()) + ", ";
        }
        result = result.substring(0, result.length-2);
        return result;
    }
    function calculateGebaudeTyp(gebaudetyp) {
        switch (gebaudetyp) {
            case 'housing': return 'Wohnen';
            case 'mixed': return 'Mischnutzung';
            case 'commercial': return 'Gewerbe';
            case 'other': return 'Andere';
            default: return 'N/A';
        }
    }

    function ChangeView({bounds, zoom}) {
        const map = useMap();
        if (!lockMap) {
            map.fitBounds(bounds);
        }
        return null;
    }

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function goToMapView() {
        setCurrentView('map');
        //setLockMap(previousLockMap);
        setMinGeschossPotential(minGeschossPotential-1);
        setMinGeschossPotential(minGeschossPotential+1);
    }

    function goToTableView() {
        setPreviousLockMap(lockMap);
        setLockMap(false);
        setCurrentView('table');
    }

    function handleSorting(newSortProperty) {
        if (sortingProperty.property === newSortProperty)
            if (sortingProperty.direction === 'ASC')
                setSortingProperty({property: sortingProperty.property, direction: 'DESC'});
            else
                setSortingProperty({property: sortingProperty.property, direction: 'ASC'});
        else {
            setSortingProperty({property: newSortProperty, direction: 'ASC'});
        }
    }

    function SpinnerComponent() {
        const map = useMap();
        map.eachLayer(layer => {
            layer.on("add", function() {
                map.spin(true);
            });
            layer.on("loading", function() {
                map.spin(true);
            });
            layer.on("load", function() {
                map.spin(false);
            });
        });
        return null;
    }
    let renderedPage = Auth("ROLE_USER");

    function toggleTableRowsVisibility(tableId) {
        var tableOfAddresses = document.getElementById(tableId);
        var rows = tableOfAddresses.rows;
        for (var i = 1; i < rows.length; i++) {
            if (rows[i].className === 'no-border no-padding')
                rows[i].className = 'no-border no-padding hidden';
            else
                rows[i].className = 'no-border no-padding';
        }
    }

    function calculateAddresses(input, rowNum) {
        var arr = input.split("\t");
        return (<table id={"addresses" + rowNum}><tbody>
                {
                    arr != null && arr.map((aRow, i) => {
                        if (i !== 0) return (<tr key={"address" + aRow} className={"no-border no-padding hidden"}>
                            <td className={"no-border no-padding"}>{aRow}</td>
                        </tr>)
                        if (i === 0 && arr.length > 1) return (<tr key={"address" + aRow} className={"no-border no-padding"}>
                            <td className={"no-border no-padding"}>{aRow} <a className={"right small-text with-pointer-events"} onClick={event =>  toggleTableRowsVisibility("addresses" + rowNum)}>{arr.length-1} mehr</a></td>
                        </tr>)
                        if (i === 0 && arr.length === 1) return (<tr key={"address" + aRow} className={"no-border no-padding"}>
                            <td className={"no-border no-padding"}>{aRow}</td>
                        </tr>)

                    })
            }
        </tbody></table>);
    }

    function toggleMap(aRow) {
        console.log(aRow);
        var bounds = [[aRow.latitudeNorth, aRow.longitudeWest], [aRow.latitudeSouth, aRow.longitudeEast]];
        var center = [aRow.latitude, aRow.longitude];
        var geometry = JSON.parse(aRow.geometry);

    }

    if (renderedPage !== "") {
        return <Navigate replace to={renderedPage} />;
    } else {
        const user = jwtDecode(payload);
        return (
            <div id={"navigator-body"}>
                <div className={"row"}>
                    <Menu callBack={setPayload}/>
                </div>
                <div className={"row"}>
                    <div className={"col s12 m12 l10 xl10 "}>
                        <div className={"row"}>
                            <div className={"col s12"}>
                                {currentView === 'map' && <a className={"btn"}>Karte</a> }
                                {currentView === 'table' && <a className={"btn disabled with-pointer-events"} onClick={event => goToMapView()}>Karte</a> }
                                {currentView === 'map' &&<a className={"btn disabled with-pointer-events"} onClick={event => goToTableView()}>Tabelle</a> }
                                {currentView === 'table' &&<a className={"btn"}>Tabelle</a> }
                            </div>
                        </div>
                        { currentView === 'table' &&
                            <div className={"row"}>
                                <div className={"col s12 table-container"}>
                                    <table className={"table-navigator"}>
                                        <thead>
                                        <tr>
                                            <td className={(sortingProperty.property === 'dataset' && 'darken-1 blue-grey white-text navigator-table-data-header') || (sortingProperty.property !== 'dataset' && 'blue-grey white-text navigator-table-data-header')} onClick={event => handleSorting('dataset')}>
                                                Kanton {sortingProperty.property === 'dataset' && sortingProperty.direction === 'DESC' && <i className={"material-icons"}>arrow_drop_down</i> }
                                                {sortingProperty.property === 'dataset' && sortingProperty.direction === 'ASC' && <i className={"material-icons"}>arrow_drop_up</i> }
                                            </td>
                                            <td className={(sortingProperty.property === 'gemeinde' && 'darken-1 blue-grey white-text navigator-table-data-header') || (sortingProperty.property !== 'gemeinde' && 'blue-grey white-text navigator-table-data-header')} onClick={event => handleSorting('gemeinde')}>
                                                Gemeinde {sortingProperty.property === 'gemeinde' && sortingProperty.direction === 'DESC' && <i className={"material-icons"}>arrow_drop_down</i> }
                                                {sortingProperty.property === 'gemeinde' && sortingProperty.direction === 'ASC' && <i className={"material-icons"}>arrow_drop_up</i> }
                                            </td>
                                            <td className={(sortingProperty.property === 'egris' && 'darken-1 blue-grey white-text navigator-table-data-header') || (sortingProperty.property !== 'egris' && 'blue-grey white-text navigator-table-data-header')} onClick={event => handleSorting('egris')}>
                                                EGRIS-EGRI {sortingProperty.property === 'egris' && sortingProperty.direction === 'DESC' && <i className={"material-icons"}>arrow_drop_down</i> }
                                                {sortingProperty.property === 'egris' && sortingProperty.direction === 'ASC' && <i className={"material-icons"}>arrow_drop_up</i> }
                                            </td>
                                            <td className={(sortingProperty.property === 'flaeche' && 'darken-1 blue-grey white-text navigator-table-data-header') || (sortingProperty.property !== 'flaeche' && 'blue-grey white-text navigator-table-data-header')} onClick={event => handleSorting('flaeche')}>
                                                Fläche {sortingProperty.property === 'flaeche' && sortingProperty.direction === 'DESC' && <i className={"material-icons"}>arrow_drop_down</i> }
                                                {sortingProperty.property === 'flaeche' && sortingProperty.direction === 'ASC' && <i className={"material-icons"}>arrow_drop_up</i> }
                                            </td>
                                            {/*<td className={(sortingProperty.property === 'nonBuildableSurface' && 'darken-1 blue-grey white-text navigator-table-data-header') || (sortingProperty.property !== 'nonBuildableSurface' && 'blue-grey white-text navigator-table-data-header')} onClick={event => handleSorting('nonBuildableSurface')}>*/}
                                            {/*    Non buildable surface {sortingProperty.property === 'nonBuildableSurface' && sortingProperty.direction === 'DESC' && <i className={"material-icons"}>arrow_drop_down</i> }*/}
                                            {/*    {sortingProperty.property === 'nonBuildableSurface' && sortingProperty.direction === 'ASC' && <i className={"material-icons"}>arrow_drop_up</i> }*/}
                                            {/*</td>*/}
                                            <td className={(sortingProperty.property === 'potentialM2Pc' && 'darken-1 blue-grey white-text navigator-table-data-header') || (sortingProperty.property !== 'potentialM2Pc' && 'blue-grey white-text navigator-table-data-header')} onClick={event => handleSorting('potentialM2Pc')}>
                                                Max. Nutzungspotenzial {sortingProperty.property === 'potentialM2Pc' && sortingProperty.direction === 'DESC' && <i className={"material-icons"}>arrow_drop_down</i> }
                                                {sortingProperty.property === 'potentialM2Pc' && sortingProperty.direction === 'ASC' && <i className={"material-icons"}>arrow_drop_up</i> }
                                            </td>
                                            <td className={(sortingProperty.property === 'reserveM2Pc' && 'darken-1 blue-grey white-text navigator-table-data-header') || (sortingProperty.property !== 'reserveM2Pc' && 'blue-grey white-text navigator-table-data-header')} onClick={event => handleSorting('reserveM2Pc')}>
                                                Ausnutzungsreserve {sortingProperty.property === 'reserveM2Pc' && sortingProperty.direction === 'DESC' && <i className={"material-icons"}>arrow_drop_down</i> }
                                                {sortingProperty.property === 'reserveM2Pc' && sortingProperty.direction === 'ASC' && <i className={"material-icons"}>arrow_drop_up</i> }
                                            </td>
                                            <td className={(sortingProperty.property === 'maxFloorPotential' && 'darken-1 blue-grey white-text navigator-table-data-header') || (sortingProperty.property !== 'maxFloorPotential' && 'blue-grey white-text navigator-table-data-header')} onClick={event => handleSorting('maxFloorPotential')}>
                                                Geschosspotenzial {sortingProperty.property === 'maxFloorPotential' && sortingProperty.direction === 'DESC' && <i className={"material-icons"}>arrow_drop_down</i> }
                                                {sortingProperty.property === 'maxFloorPotential' && sortingProperty.direction === 'ASC' && <i className={"material-icons"}>arrow_drop_up</i> }
                                            </td>
                                            <td className={(sortingProperty.property === 'buildingsCount' && 'darken-1 blue-grey white-text navigator-table-data-header') || (sortingProperty.property !== 'buildingsCount' && 'blue-grey white-text navigator-table-data-header')} onClick={event => handleSorting('buildingsCount')}>
                                                Gebäude {sortingProperty.property === 'buildingsCount' && sortingProperty.direction === 'DESC' && <i className={"material-icons"}>arrow_drop_down</i> }
                                                {sortingProperty.property === 'buildingsCount' && sortingProperty.direction === 'ASC' && <i className={"material-icons"}>arrow_drop_up</i> }
                                            </td>
                                            <td className={(sortingProperty.property === 'builtArea' && 'darken-1 blue-grey white-text navigator-table-data-header') || (sortingProperty.property !== 'builtArea' && 'blue-grey white-text navigator-table-data-header')} onClick={event => handleSorting('builtArea')}>
                                                Bebaute Fläche {sortingProperty.property === 'builtArea' && sortingProperty.direction === 'DESC' && <i className={"material-icons"}>arrow_drop_down</i> }
                                                {sortingProperty.property === 'builtArea' && sortingProperty.direction === 'ASC' && <i className={"material-icons"}>arrow_drop_up</i> }
                                            </td>
                                            <td className={(sortingProperty.property === 'freeAreaPc' && 'darken-1 blue-grey white-text navigator-table-data-header') || (sortingProperty.property !== 'freeAreaPc' && 'blue-grey white-text navigator-table-data-header')} onClick={event => handleSorting('freeAreaPc')}>
                                                Freie Fläche {sortingProperty.property === 'freeAreaPc' && sortingProperty.direction === 'DESC' && <i className={"material-icons"}>arrow_drop_down</i> }
                                                {sortingProperty.property === 'freeAreaPc' && sortingProperty.direction === 'ASC' && <i className={"material-icons"}>arrow_drop_up</i> }
                                            </td>
                                            <td className={(sortingProperty.property === 'totalVolume' && 'darken-1 blue-grey white-text navigator-table-data-header') || (sortingProperty.property !== 'totalVolume' && 'blue-grey white-text navigator-table-data-header')} onClick={event => handleSorting('totalVolume')}>
                                                Total Volumen {sortingProperty.property === 'totalVolume' && sortingProperty.direction === 'DESC' && <i className={"material-icons"}>arrow_drop_down</i> }
                                                {sortingProperty.property === 'totalVolume' && sortingProperty.direction === 'ASC' && <i className={"material-icons"}>arrow_drop_up</i> }
                                            </td>
                                            <td className={(sortingProperty.property === 'totalM2' && 'darken-1 blue-grey white-text navigator-table-data-header') || (sortingProperty.property !== 'totalM2' && 'blue-grey white-text navigator-table-data-header')} onClick={event => handleSorting('totalM2')}>
                                                BGF {sortingProperty.property === 'totalM2' && sortingProperty.direction === 'DESC' && <i className={"material-icons"}>arrow_drop_down</i> }
                                                {sortingProperty.property === 'totalM2' && sortingProperty.direction === 'ASC' && <i className={"material-icons"}>arrow_drop_up</i> }
                                            </td>
                                            <td className={(sortingProperty.property === 'strType' && 'darken-1 blue-grey white-text navigator-table-data-header') || (sortingProperty.property !== 'strType' && 'blue-grey white-text navigator-table-data-header')} onClick={event => handleSorting('strType')}>
                                                Gebäudetyp {sortingProperty.property === 'strType' && sortingProperty.direction === 'DESC' && <i className={"material-icons"}>arrow_drop_down</i> }
                                                {sortingProperty.property === 'strType' && sortingProperty.direction === 'ASC' && <i className={"material-icons"}>arrow_drop_up</i> }
                                            </td>
                                            <td className={(sortingProperty.property === 'addresses' && 'darken-1 blue-grey white-text navigator-table-data-header') || (sortingProperty.property !== 'addresses' && 'blue-grey white-text navigator-table-data-header')} onClick={event => handleSorting('addresses')}>
                                                Adresse {sortingProperty.property === 'addresses' && sortingProperty.direction === 'DESC' && <i className={"material-icons"}>arrow_drop_down</i> }
                                                {sortingProperty.property === 'addresses' && sortingProperty.direction === 'ASC' && <i className={"material-icons"}>arrow_drop_up</i> }
                                            </td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {tableData != null && tableData.map((aRow, i) => {
                                            return(<tr key={aRow.egris}>
                                                <td className={"navigator-table-data"}>{aRow != null && aRow.dataset != null && calculateKanton(aRow.dataset)}</td>
                                                <td className={"navigator-table-data"}>{aRow != null && aRow.gemeinde != null && aRow.gemeinde}</td>
                                                <td className={"navigator-table-data"}>
                                                    <div>
                                                        <a className={"with-pointer-events"} title={"Report"} target={'_blank'} href={'/report?egris=' + aRow.egris + '&dataset=' + aRow.dataset}>{aRow != null && aRow.egris != null && aRow.egris}</a>
                                                        <a className={"material-icons with-pointer-events medium-text"} target={'_blank'} title={"Report"} href={'/report?egris=' + aRow.egris + '&dataset=' + aRow.dataset}>open_in_new</a>
                                                        <Popup trigger={<a className={"material-icons with-pointer-events medium-text left-separator"} title={"Vorschaukarte"}>map</a>} position="right center" width={"300px"}>
                                                            <MapContainer className={"map-table col s12"} bounds={[[aRow.latitudeNorth, aRow.longitudeWest], [aRow.latitudeSouth, aRow.longitudeEast]]} center={[aRow.latitude, aRow.longitude]} scrollWheelZoom={true}>
                                                                <TileLayer attribution='&copy; <a href="https://www.geo.admin.ch/">geo.admin.ch das Geoportal des Bundes</a> contributors'
                                                                           url="https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.swissimage/default/current/3857/{z}/{x}/{y}.jpeg"
                                                                           opacity={0.7}
                                                                           maxZoom={30}
                                                                           maxNativeZoom={18}
                                                                />
                                                                <GeoJSON key='geometry' data={JSON.parse(aRow.geometry)}
                                                                         fillColor={'#e3f2fd'} fillOpacity={.4} stroke={true} weight={0.8} color={"#bbdefb"}
                                                                />
                                                            </MapContainer>
                                                        </Popup>
                                                    </div>
                                                </td>
                                                <td className={"navigator-table-data"}>{aRow != null && aRow.flaeche != null && aRow.flaeche} m2</td>
                                                {/*<td className={"navigator-table-data"}>{aRow != null && aRow.nonBuildableSurface != null && numberWithCommas(aRow.nonBuildableSurface)} m2</td>*/}
                                                <td className={"navigator-table-data"}>{aRow != null && aRow.potentialM2Pc != null && Math.round(aRow.potentialM2Pc*100)}% ({aRow != null && aRow.potentialM2 != null && numberWithCommas((''+aRow.potentialM2).split('.')[0])} m2)</td>
                                                <td className={"navigator-table-data"}>{aRow != null && aRow.reserveM2Pc  != null  && Math.round(aRow.reserveM2Pc*100)}% ({aRow != null && aRow.reserveM2  != null && numberWithCommas((''+aRow.reserveM2).split('.')[0])} m2)</td>
                                                <td className={"navigator-table-data"}>{aRow != null && aRow.maxFloorPotential  != null && aRow.maxFloorPotential}</td>
                                                <td className={"navigator-table-data"}>{aRow != null && aRow.buildingsCount  != null && aRow.buildingsCount}</td>
                                                <td className={"navigator-table-data"}>{aRow != null && aRow.builtArea  != null && numberWithCommas(aRow.builtArea)} m2</td>
                                                <td className={"navigator-table-data"}>{aRow != null && aRow.freeAreaPc  != null &&  Math.round(aRow.freeAreaPc*100)}% ({aRow != null && aRow.freeArea  != null && numberWithCommas((''+aRow.freeArea).split('.')[0])} m2)</td>
                                                <td className={"navigator-table-data"}>{aRow != null && aRow.totalVolume  != null && numberWithCommas(aRow.totalVolume)} m3</td>
                                                <td className={"navigator-table-data"}>{aRow != null && aRow.totalM2  != null && numberWithCommas(aRow.totalM2)} m2</td>
                                                <td className={"navigator-table-data"}>{aRow != null && aRow.strType  != null && calculateGebaudeTypes(aRow.strType.split(","))}</td>
                                                <td className={"navigator-table-data"}>{aRow != null && aRow.addresses  != null && calculateAddresses(aRow.addresses, i)}</td>
                                            </tr>)})
                                        }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                        { currentView === 'map' && <div>
                            <div className={"map-overlay-navigator"} onClick={e => lockMapChanged(!lockMap)}>
                                { !lockMap && <span className={"material-icons black-text"} style={{"cursor": "pointer"}} onClick={e => lockMapChanged(!lockMap)}>gps_not_fixed</span> }
                                { lockMap && <span className={"material-icons red-text"} style={{"cursor": "pointer"}} onClick={e => lockMapChanged(!lockMap)}>gps_fixed</span> }
                            </div>
                            <MapContainer className={"map-navigator-box content"} bounds={bounds} center={[47.3382723372246, 7.33631501368192]} zoom={13} scrollWheelZoom={true}>
                                <ChangeView bounds={bounds}/>
                                <SpinnerComponent />
                                <TileLayer
                                    attribution='&copy; <a href="https://www.geo.admin.ch/">geo.admin.ch
        das Geoportal des Bundes</a> contributors'
                                    url="https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.swissimage/default/current/3857/{z}/{x}/{y}.jpeg"
                                    opacity={0.7}
                                />
                                <BetterWMS
                                    id={"wmsLayer"}
                                    params={{
                                        "layers": "dedomena:mv_cadastre_x_derived_indicators",
                                        "cql_filter": "(" +
                                            createFlaecheCQLQuery(selectedFlaeche) +
                                            createFreeFlaecheCQLQuery(selectedFreeFlaeche) +
                                            createAddresssFilter(addresseFilter) +
                                            "(potential_m2_pc IS NULL OR " +
                                            "(potential_m2_pc>=" +minPotentialM2Query + /*" AND potential_m2_pc<=" + maxPotentialM2Query + */")) AND " +
                                            "(reserve_m2_pc IS NULL OR " +
                                            "(reserve_m2_pc>=" +minReserveM2Query + /*" AND reserve_m2_pc<=" + maxReserveM2Query +*/ ")) AND " +
                                            "(max_floor_potential IS NULL OR " +
                                            "(max_floor_potential>=" +minGeschossPotential + /*" AND max_floor_potential<=" + maxFloorPotential +*/ ")) " +
                                            " AND dataset='" + selectedDataset + "'" +
                                            (selectedGemeinde != null && selectedGemeinde.length > 0 ? " AND gemeinde LIKE '" + selectedGemeinde + "'" : "") +
                                            (buildingType != null && buildingType.length > 0 && buildingType !== 'Alle' ? " AND obj_type_str LIKE '" + buildingType + "'" : "") +
                                            ")",
                                        "styles": "reserve_m2_pc"
                                    }}
                                    key={selectedDataset}
                                    url="/geo"
                                    opacity={0.6}
                                    format={"image/png"}
                                    transparent={"true"}
                                    info_format={"application/json"}
                                    // bounds={bounds}
                                />
                                <TileLayer
                                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager_only_labels/{z}/{x}/{y}{r}.svg"
                                    opacity={1}
                                    zIndex={3}
                                />
                            </MapContainer>
                        </div>}
                    </div>
                    <div id={"filtersForm"} className={"col s12 m12 l2 xl2"}>
                        <h5 className={"bottom-separator valign-wrapper"}>Filter <a style={{"cursor": "pointer", "marginLeft": "15px"}} className={"material-icons"} onClick={e => deleteFilters()}>clear</a></h5>
                        <div className={"row"}>
                            <div className={"col s12"}>
                                <label className={"bottom-separator"}>Kantone:</label>
                                <select id={"dataset"} className={"browser-default"}
                                        value={selectedDataset}
                                        onChange={e => datasetChanged(e.target.value)}
                                >
                                    {
                                        datasets.map(function(element){
                                            return(
                                                <option value={element.dataset} key={element.dataset}> {calculateKanton(element.dataset)}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col s12"}>
                                <label className={"bottom-separator"}>Gemeinden:</label>
                                <select id={"gemeinde"} className={"browser-default"}
                                        value={selectedGemeinde}
                                        onChange={e => gemeindeChanged(e.target.value)}
                                >
                                    <option value={"Alle"} key={"alleGemeinde"}>Alle</option>
                                    {
                                        gemeindes.map(function(element){
                                            return(
                                                <option value={element.gemeinde} key={element.gemeinde}> {element.gemeinde}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col s12"}><label className={"bottom-separator"}>Adresse:</label></div>
                            <div className={"input-field col s12"}>
                                <ReactSearchBox
                                    data={addresseData}
                                    clearOnSelect={false}
                                    value={addresse}
                                    onChange={e => addresseChanged(e)}
                                    onSelect={e => addresseSelected(e)}
                                    callback={(record) => console.log(record)}
                                />
                                {/*<label className={"bottom-separator"}>*/}
                                {/*    { (addresse != null && addresse.length > 0) && <span>{addresse} <a onClick={e => deleteAddress()} style={{"cursor": "pointer"}}>X</a></span> }*/}
                                {/*</label>*/}
                            </div>
                            <div className={"col s12"}>
                                <label className={"bottom-separator"}>Parzellenfläche:</label>
                                <select id={"flaeche"} className={"browser-default"}
                                        value={selectedFlaeche[0]}
                                        onChange={e => flaecheChanged(true, e.target.value)}
                                >
                                    <option value={"Alle"} key={"alleFlaeche"}>Alle</option>
                                    <option value={"0"} key={"0F"}>0 - 1000 m2</option>
                                    <option value={"1"} key={"1F"}>1000 - 5000 m2</option>
                                    <option value={"2"} key={"2F"}>5000 - 10000 m2</option>
                                    <option value={"3"} key={"3F"}>10000 - 50000 m2</option>
                                    <option value={"4"} key={"4F"}>> 50000 m2</option>

                                </select>
                            </div>
                            <div className={"col s12"}>
                                <label className={"bottom-separator"}>Freie Parzellenfläche %:</label>
                                <select id={"freieFlaeche"} className={"browser-default"}
                                        value={selectedFreeFlaeche[0]}
                                        onChange={e => freeFlaecheChanged(true, e.target.value)}
                                >
                                    <option value={"Alle"} key={"alleFreeFlaeche"}>Alle</option>
                                    <option value={"0"} key={"0FF"}>&lt; 20%</option>
                                    <option value={"1"} key={"1F"}>20 - 50%</option>
                                    <option value={"2"} key={"2F"}>50 - 80%</option>
                                    <option value={"3"} key={"3F"}>80 - 85%</option>
                                    <option value={"4"} key={"4F"}>> 85%</option>

                                </select>
                            </div>
                            <div className={"col s12"}>
                                <label className={"bottom-separator"}>Gebäudetyp:</label>
                                <select id={"buildingType"} className={"browser-default"}
                                        value={buildingType}
                                        onChange={e => buildingTypeChanged(true, e.target.value)}
                                >
                                    <option value={"Alle"} key={"alleBuildingTypes"}>Alle</option>
                                    <option value={"commercial"} key={"0BT"}>Gewerbe</option>
                                    <option value={"housing"} key={"1F"}>Wohnen</option>
                                    <option value={"mixed"} key={"2F"}>Mischnutzung</option>
                                    <option value={"other"} key={"3F"}>Andere</option>
                                </select>
                            </div>
                            <div className={"input-field col s12"}>
                                <div className={"row top-slider-separator"}>
                                    <div className={"col s10 offset-s1"} id="floor-potential-slider"></div>
                                </div>
                                <label>Geschosspotenzial:</label>
                            </div>
                            <div className={"input-field col s12"}>
                                <div className={"row top-slider-separator"}>
                                    <div className={"col s10 offset-s1"} id="max-reserve-slider"></div>
                                </div>
                                <label>Ausnutzungsreserve in %:</label>
                            </div>
                            <div className={"input-field col s12"}>
                                <div className={"row top-slider-separator"}>
                                    <div className={"col s10 offset-s1"} id="max-potential-m2-slider"></div>
                                </div>
                                <label>Max. Nutzungspotenzial %:</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default Navigator;