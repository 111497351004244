import React, {useState} from 'react';
import Menu from "../menu-component/Menu";
import UsersList from "./UsersList";
import {Navigate} from "react-router-dom";
import {jwtDecode} from "jwt-decode";
import {Button} from "react-bootstrap";
import Auth from "../../common/Auth";

const Users = () => {
    const [payload, setPayload] = useState(localStorage.getItem("token"));

    let renderedPage = Auth("ROLE_ADMIN");
    if (renderedPage !== "") {
        return <Navigate replace to={renderedPage} />;
    } else {
        const user = jwtDecode(payload);
        return (
            <div>
                <div className={"row"}>
                    <Menu callBack={setPayload}/>
                </div>
                <div className={"row"}>
                    <h2 className={"col s10"}>Users</h2>
                    <div className={"col s2"} >
                        <a href={"/user-create"} className={"btn red white-text"}>
                            <span className={"valign-wrapper"}>
                                Add User <i className={"material-icons"}>add</i>
                            </span>
                        </a>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col s12"}>
                        <UsersList callBack={"setUserToEdit"}/>
                    </div>
                </div>
            </div>
        )
    }
}
export default Users;