import logo from "../../logo.png";
import React from 'react';
import {useState} from 'react';
import M from 'materialize-css';
import {useNavigate} from "react-router-dom";

const Login = () => {
    const [login, setlogin] = useState("");
    const [password, setpassword] = useState("");
    const [authenticated, setauthenticated] = useState(localStorage.getItem("authenticated"));
    const navigate = useNavigate();

    const sendLogin = (e) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                login: login,
                password: password
            })
        };
        fetch(process.env.REACT_APP_API_URL + '/dedomena-user-management/users/login', requestOptions)
            .then(async response => {
                const isText = response.headers.get('content-type')?.includes('text');
                const data = isText && await response.text();

                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                } else {
                    // M.toast({
                    //     html: '<n:i class="material-icons">check_circle</n:i> Authentication data ok',
                    //     classes: 'green lighten-1 white-text'});
                    setauthenticated(true)
                    localStorage.setItem("authenticated", authenticated);
                    localStorage.setItem("token", data);
                    navigate("/");
                }

            })
            .catch(error => {
                console.log(error);
                M.toast({
                    html: '<n:i class="material-icons">error</n:i> The authentication data does not match any user in Dedomena. Try again.',
                    classes: 'red lighten-1 white-text'});
            });
    };
    return (
        <div className="App">
            <div className="App-header">
                <div className="card">
                    <p>
                        <b>{process.env.NODE_ENV}</b>
                    </p>
                    <img src={logo} className="App-logo" alt="logo" />
                    <div className="container row">
                        <div className="col s12">
                            <div className="row">
                                <div className="input-field col s12">
                                    <i className="material-icons prefix">mail</i>
                                    <input id="email" type="email" value={login}
                                           onChange={(event) => {
                                               setlogin(event.target.value);
                                           }}
                                           className="validate" />
                                    <label htmlFor="email">Email</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s12">
                                    <i className="material-icons prefix">password</i>
                                    <input id="password" type="password" value={password}
                                           onChange={(event) => {
                                               setpassword(event.target.value);
                                           }}
                                           className="validate" />
                                    <label htmlFor="password">Password</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className={"col s6 offset-s6"}>
                                    <button className="btn waves-effect red white-text right" onClick={sendLogin} name="action">Log in
                                        <i className="material-icons right">send</i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default Login;
