import React, {useEffect} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'materialize-css/dist/css/materialize.min.css'
import 'materialize-css/dist/js/materialize.min'
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Login from "./components/login-component/Login";
import Users from "./components/users-component/Users";
import Dashboard from "./components/dashboard-component/Dashboard";
import UserEdit from "./components/users-component/UserEdit";
import './App.css';
import Navigator from "./components/navigator-component/Navigator";
import UserCreate from "./components/users-component/UserCreate";
import Report from "./components/report-component/Report";
import './i18n.js'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter>
          <Routes>
              <Route path="login" element={<Login />} />
              <Route path="users" element={<Users />} />
              <Route path="user-edit" element={<UserEdit />} />
              <Route path="user-create" element={<UserCreate />} />
              <Route path="navigator" element={<Navigator />} />
              <Route path="report" element={<Report />} />
              <Route index element={<Dashboard />} />
          </Routes>
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
