import React, {useEffect, useState} from "react";
import M from "materialize-css";
import Menu from "../menu-component/Menu";
import {Navigate, useHref, useNavigate} from "react-router-dom";
import Auth from "../../common/Auth";


const UserCreate = () => {
    const [payload, setPayload] = useState(localStorage.getItem("token"));
    const [login, setlogin] = useState("");
    const [password, setpassword] = useState("");
    const [name, setname] = useState("");
    const [surname, setsurname] = useState("");
    const [phone, setphone] = useState("");
    const [role, setrole] = useState("");

    const navigate = useNavigate();

    useEffect(() => {
        M.AutoInit(document.getElementById('userCreationForm'));
        window.M.updateTextFields();
    }, []);

    const saveUser = (e) => {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            },
            body: JSON.stringify({
                login: login,
                password: password,
                name: name,
                surname: surname,
                email: login,
                phone: phone,
                role: role,
            })
        };
        console.log(requestOptions.body);
        fetch(process.env.REACT_APP_API_URL + '/dedomena-user-management/users', requestOptions)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();

                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                } else {
                    M.toast({
                         html: '<n:i class="material-icons">check_circle</n:i> User saved succesfully',
                         classes: 'green lighten-1 white-text'});
                    navigate("/users");
                }

            })
            .catch(error => {
                console.log(error);
                M.toast({
                    html: '<n:i class="material-icons">error</n:i> Cannot update user. Try again later.',
                    classes: 'red lighten-1 white-text'
                });
            });
    };
    let renderedPage = Auth("ROLE_ADMIN");
    if (renderedPage !== "") {
         return <Navigate replace to={renderedPage} />;
    } else {
        return (
            <div id={"userCreationForm"}>
                {/*<Auth role={"ROLE_ADMIN"} />*/}
                <div className={"row"}>
                    <Menu callBack={setPayload}/>
                </div>
                <div className={"container row"}>
                    <h2 className={"col s12"}>User creation</h2>
                </div>
                <div className="container row">
                    <div className="col s12">
                        <div className="row">
                            <div className="input-field col s12">
                                <i className="material-icons prefix">mail</i>
                                <input id="email" type="email"
                                       onChange={(event) => {
                                           setlogin(event.target.value);
                                       }}
                                       className="validate"/>
                                <label htmlFor="email">Email</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="input-field col s12">
                                <i className="material-icons prefix">password</i>
                                <input id="password" type="password"
                                       className="validate"
                                       onChange={(event) => {
                                           setpassword(event.target.value);
                                       }}/>
                                <label htmlFor="password">Password</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="input-field col s12">
                                <i className="material-icons prefix">edit</i>
                                <input id="name" type="text"
                                       className="validate"
                                       onChange={(event) => {
                                           setname(event.target.value);
                                       }}/>
                                <label htmlFor="name">Name</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="input-field col s12">
                                <i className="material-icons prefix">edit</i>
                                <input id="surname" type="text"
                                       className="validate"
                                       onChange={(event) => {
                                           setsurname(event.target.value);
                                       }}/>
                                <label htmlFor="surname">Surname</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="input-field col s12">
                                <i className="material-icons prefix">phone</i>
                                <input id="phone" type="text"
                                       className="validate"
                                       onChange={(event) => {
                                           setphone(event.target.value);
                                       }}/>
                                <label htmlFor="phone">Phone</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="input-field col s12">
                                <i className="material-icons prefix">key</i>
                                <select id={"role"}
                                        value={role}
                                        onChange={e => setrole(e.target.value)}>
                                    <option value="ROLE_ADMIN">Administrator</option>
                                    <option value="ROLE_USER">User</option>
                                </select>
                                <label>Role</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className={"col s6 offset-s6"}>
                                <button className="btn waves-effect red white-text right" onClick={saveUser}
                                        name="action">Save
                                    <i className="material-icons right">save</i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default UserCreate;