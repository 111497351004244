import React, {useEffect, useRef, useState} from "react";
import M from "materialize-css";
import './Report.css';
import {Navigate, useSearchParams} from "react-router-dom";
import Auth from "../../common/Auth";
import Menu from "../menu-component/Menu";
import {jwtDecode} from "jwt-decode";
import 'leaflet/dist/leaflet.css';
import {GeoJSON, MapContainer, Marker, TileLayer, Popup, useMap} from "react-leaflet";
import L from 'leaflet';
import useFullPageLoader from "../../hooks/useFullPageLoader";
import { useTranslation } from "react-i18next";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);
delete L.Icon.Default.prototype._getIconUrl;

const reportIcon = L.icon({
    iconUrl: require('./marker.png'),

    iconSize:     [8, 8], // size of the icon
    shadowSize:   [0, 0], // size of the shadow
    iconAnchor:   [8, 8], // point of the icon which will correspond to marker's location
    shadowAnchor: [0, 0],  // the same for the shadow
    popupAnchor:  [-3, -10] // point from which the popup should open relative to the iconAnchor
});

function calculateColor(aPoiType) {
    if  (aPoiType === '003_DIM_ECO')
        return "blue";
    if  (aPoiType === '004_DIM_ENV')
        return "green";
    if  (aPoiType === '001_DIM_COM')
        return "orange";
    if  (aPoiType === '002_DIM_DES')
        return "red";
    else return "grey";
}

const poiIcon = (aPoiType) => L.icon({
        iconUrl: require('./marker-' + calculateColor(aPoiType) +'.png'),
        iconAnchor: [8, 8], // point of the icon which will correspond to marker's location
        shadowAnchor: [10, 10],  // the same for the shadow
        popupAnchor: [-3, -10], // point from which the popup should open relative to the iconAnchor
        shadowUrl: require('./dot-shadow.png'),
        shadowSize:   [13, 13],
        iconSize:     [9, 9]
    }
)


const Report = () => {
    const { t, i18n } = useTranslation();
    const [payload, setPayload] = useState(localStorage.getItem("token"));
    const [searchParams, setSearchParams] = useSearchParams();
    const [dataset, setDataset] = useState(searchParams.get("dataset"));
    const [egris, setEgris] = useState(searchParams.get("egris"));
    const [reportData, setReportData] = useState();
    const [mapGeometryBounds, setMapGeometryBounds] = useState([[47.3382723372246, 7.33631501368192],[47.56436509534766, 7.945061760112403]]);
    const [mapGeometryBoundsPoi, setMapGeometryBoundsPoi] = useState([[47.3382723372246, 7.33631501368192],[47.56436509534766, 7.945061760112403]]);
    const [mapZonesBounds, setMapZonesBounds] = useState([[47.3382723372246, 7.33631501368192],[47.56436509534766, 7.945061760112403]]);
    const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [zonenGeoJson, setZonenGeoJson] = useState()
    const [mapBuildingsInfo, setMapBuildingsInfo] = useState('');
    const [mapZonesInfo, setMapZonesInfo] = useState('');
    const [lockMapZones, setLockMapZones] = useState(false);
    const [lockMapBuildings, setLockMapBuildings] = useState(false);
    const [poiDistance, setPoiDistance] = useState(500);
    const [poiPopularity, setPoiPopularity] = useState(0);
    const chartRef = useRef(null);
    const [categoryFilter, setCategoryFilter] = useState(null);
    const [totalPoiNumber, setTotalPoiNumber] = useState(0);
    var chartSortedArray = [];
    const categories = ['005_CAT_CLUB-RELIG', '006_CAT_EDU', '007_CAT_GOV-COM',
        '008_CAT_HEALTH-WS', '009_CAT_ACCOM', '010_CAT_ART-CULT', '011_CAT_ATTRACT',
        '012_CAT_HOSP', '013_CAT_RETAIL', '014_CAT_GENERAL', '015_CAT_PRO',
        '016_CAT_TECH-IND', '017_CAT_PUBLIC', '018_CAT_TRANSIT', '019_CAT_OTHER-PUB'
    ]

    const [chartOptions, setChartOptions] = useState({
        onClick: function(evt, element) {
            if (element.length > 0)
            {
                console.log(element[0]);
                console.log(chartSortedArray);
                setCategoryFilter(chartSortedArray[element[0].datasetIndex].translation.substring(chartSortedArray[element[0].datasetIndex].translation.lastIndexOf(".")+1));
            } else setCategoryFilter(null);
        },
        plugins: {
            title: {
                display: false,
            },
            legend: {
                display: false
            },
            datalabels: {
                display: false,
                anchor: 'center',
                color: '#FFF',
                font: {
                    weight: 'bold'
                },
                formatter: function(value, context) {
                    if (value != 0)
                        return context.dataset.label + ': ' + value;
                    else return '';
                }
            }
        },
        responsive: true,
        scales: {
            x: {
                title: {
                  display: false
                },
                grid: {
                    display: false,
                },
                stacked: true,
            },
            y: {
                grid: {
                    display: false,
                },
                stacked: true,
            },
        },
    });
    const [chartData, setChartData] = useState({
                labels: [],
                datasets: []
            });

    function checkNotFiltered(aPoint) {
        if (categoryFilter == null)
            return true;
        else {
            return aPoint.category === categoryFilter;
        }
    }

    function lockMapZonesChanged(checked) {
        setLockMapZones(checked);
    }

    function lockMapBuildingsChanged(checked) {
        setLockMapBuildings(checked);
    }

    function createZonenFeatures(zonen) {
        let jsonResult = "";
        jsonResult += '{"type": "FeatureCollection", "features": [ ';
        for (let i = 0; i < zonen.length; i++) {
            jsonResult += '{"type": "Feature", "geometry": ';
            let zonenJson = zonen[i].geoJson.replace("\\", "");
            jsonResult += zonenJson.substring(0, zonenJson.length -1) + '}, "properties": { "kommTyp": "' + zonen[i].typKommun + '" } },';
        }
        jsonResult = jsonResult.substring(0, jsonResult.length-1).replace('\\"', "");
        jsonResult += ']}';
        let jsonFeatures = JSON.parse(jsonResult).features;
        let maxX = -180;
        let maxY = -180;
        let minX = 180;
        let minY = 180;
        for (let i = 0; i < jsonFeatures.length; i++) {
            let coordinates = jsonFeatures[i].geometry.coordinates[0];
            for (let k = 0; k < coordinates.length; k++) {
                if (coordinates[k][0] > maxX)
                    maxX = coordinates[k][0];
                if (coordinates[k][0] < minX)
                    minX = coordinates[k][0];
                if (coordinates[k][1] > maxY)
                    maxY = coordinates[k][1];
                if (coordinates[k][1] < minY)
                    minY = coordinates[k][1];
            }
        }
        setMapZonesBounds([[minY, minX], [maxY, maxX]]);
        return jsonResult;
    }

    function calculateBoundsForPoi(pointsOfInterestInNearby) {
        let maxX = -180;
        let maxY = -180;
        let minX = 180;
        let minY = 180;
        for (let i = 0; i < pointsOfInterestInNearby.length; i++) {
            if (pointsOfInterestInNearby[i] != null) {
                if (pointsOfInterestInNearby[i].longitude > maxX)
                    maxX = pointsOfInterestInNearby[i].longitude;
                if (pointsOfInterestInNearby[i].longitude < minX)
                    minX = pointsOfInterestInNearby[i].longitude;
                if (pointsOfInterestInNearby[i].latitude > maxY)
                    maxY = pointsOfInterestInNearby[i].latitude;
                if (pointsOfInterestInNearby[i].latitude < minY)
                    minY = pointsOfInterestInNearby[i].latitude;
            }
        }
        return [[minY, minX], [maxY, maxX]];
    }

    function handleFilterPoi(filterType, value) {
        if (filterType === 'distance')
            setPoiDistance(value);
        else
            setPoiPopularity(value);
    }

   function pSBC(lighten, color) {
        const c = color.replace("#", "");
        let rgb = [parseInt(c.substr(0, 2), 16), parseInt(c.substr(2, 2), 16), parseInt(c.substr(4, 2), 16)];
        let returnstatement = "#";
        rgb.forEach((color)=>{returnstatement+=Math.round(((255-color)*(1-Math.pow(Math.E, -lighten))+color)).toString(16)});
        return returnstatement;
    }

    function sortDataArray(dataArray) {
        var orangeCategory = [dataArray[4], dataArray[5], dataArray[6], dataArray[7]];
        var redCategory = [dataArray[8], dataArray[9], dataArray[10], dataArray[11], dataArray[12]];
        var blueCategory = [dataArray[13], dataArray[14], dataArray[15]];
        var greenCategory = [dataArray[16], dataArray[17], dataArray[18]];
        orangeCategory.sort((a, b) => {
            if (a.value > b.value)
                return -1;
            else return 1;
        });
        redCategory.sort((a, b) => {
            if (a.value > b.value)
                return -1;
            else return 1;
        });
        blueCategory.sort((a, b) => {
            if (a.value > b.value)
                return -1;
            else return 1;
        });
        greenCategory.sort((a, b) => {
            if (a.value > b.value)
                return -1;
            else return 1;
        });
        var chartArray = [];
        for (var index = 0; index < orangeCategory.length; index++) {
            chartArray.push(
                {
                    label: (t(orangeCategory[index].translation) + (' (' + Math.round((orangeCategory[index].value/dataArray[0].value)* 100) + '%) ')),
                    data: [orangeCategory[index].value, 0, 0, 0],
                    backgroundColor: pSBC (index == 0 ? index : index *0.2 , '#f28e2b')
                }
            )
            chartSortedArray.push(orangeCategory[index]);
        }
        for (var index = 0; index < redCategory.length; index++) {
            chartArray.push(
                {
                    label: (t(redCategory[index].translation) + (' (' + Math.round((redCategory[index].value/dataArray[1].value)* 100) + '%) ')),
                    data: [0, redCategory[index].value, 0, 0],
                    backgroundColor: pSBC (index *0.2  , '#C92150FF')
                }
            )
            chartSortedArray.push(redCategory[index]);
        }
        for (var index = 0; index < blueCategory.length; index++) {
            chartArray.push(
                {
                    label: (t(blueCategory[index].translation) + (' (' + Math.round((blueCategory[index].value/dataArray[2].value)* 100) + '%) ')),
                    data: [0, 0, blueCategory[index].value, 0],
                    backgroundColor: pSBC (index == 0 ? index : index *0.2  , '#316FDCFF')
                }
            )
            chartSortedArray.push(blueCategory[index]);
        }
        for (var index = 0; index < greenCategory.length; index++) {
            chartArray.push(
                {
                    label: (t(greenCategory[index].translation) + (' (' + Math.round((greenCategory[index].value/dataArray[3].value)* 100) + '%) ')),
                    data: [0, 0, 0, greenCategory[index].value],
                    backgroundColor: pSBC (index == 0 ? index : index *0.2 , '#5BB71FFF')
                }
            )
            chartSortedArray.push(greenCategory[index]);
        }
        return chartArray;
    }

    function fillChartData(pointsOfInterestInNearby) {
        var elems001 = {value :0, index:1};
        var elems002 = {value :0, index:2};
        var elems003 = {value :0, index:3};
        var elems004 = {value :0, index:4};
        var elems005 = {value :0, index:5, translation: 'poi.category.005_CAT_CLUB-RELIG'};
        var elems006 = {value :0, index:6, translation: 'poi.category.006_CAT_EDU'};
        var elems007 = {value :0, index:7, translation: 'poi.category.007_CAT_GOV-COM'};
        var elems008 = {value :0, index:8, translation: 'poi.category.008_CAT_HEALTH-WS'};
        var elems009 = {value :0, index:9, translation: 'poi.category.009_CAT_ACCOM'};
        var elems010 = {value :0, index:10, translation: 'poi.category.010_CAT_ART-CULT'};
        var elems011 = {value :0, index:11, translation: 'poi.category.011_CAT_ATTRACT'};
        var elems012 = {value :0, index:12, translation: 'poi.category.012_CAT_HOSP'};
        var elems013 = {value :0, index:13, translation: 'poi.category.013_CAT_RETAIL'};
        var elems014 = {value :0, index:14, translation: 'poi.category.014_CAT_GENERAL'};
        var elems015 = {value :0, index:15, translation: 'poi.category.015_CAT_PRO'};
        var elems016 = {value :0, index:16, translation: 'poi.category.016_CAT_TECH-IND'};
        var elems017 = {value :0, index:17, translation: 'poi.category.017_CAT_PUBLIC'};
        var elems018 = {value :0, index:18, translation: 'poi.category.018_CAT_TRANSIT'};
        var elems019 = {value :0, index:19, translation: 'poi.category.019_CAT_OTHER-PUB'};
        var percentage001 = 0;
        var percentage002 = 0;
        var percentage003 = 0;
        var percentage004 = 0;
        for (var i = 0; i < pointsOfInterestInNearby.length; i++) {
            if (pointsOfInterestInNearby[i] != null) {
                //dimensions
                if (pointsOfInterestInNearby[i].dimension === '001_DIM_COM')
                    elems001.value = elems001.value + 1;
                else if (pointsOfInterestInNearby[i].dimension === '002_DIM_DES')
                    elems002.value = elems002.value + 1;
                else if (pointsOfInterestInNearby[i].dimension === '003_DIM_ECO')
                    elems003.value = elems003.value + 1;
                else if (pointsOfInterestInNearby[i].dimension === '004_DIM_ENV')
                    elems004.value = elems004.value + 1;
                //categories
                if (pointsOfInterestInNearby[i].category != null) {
                    if (pointsOfInterestInNearby[i].category.startsWith('005'))
                        elems005.value = elems005.value + 1;
                    if (pointsOfInterestInNearby[i].category.startsWith('006'))
                        elems006.value = elems006.value + 1;
                    if (pointsOfInterestInNearby[i].category.startsWith('007'))
                        elems007.value = elems007.value + 1;
                    if (pointsOfInterestInNearby[i].category.startsWith('008'))
                        elems008.value = elems008.value + 1;
                    if (pointsOfInterestInNearby[i].category.startsWith('009'))
                        elems009.value = elems009.value + 1;
                    if (pointsOfInterestInNearby[i].category.startsWith('010'))
                        elems010.value = elems010.value + 1;
                    if (pointsOfInterestInNearby[i].category.startsWith('011'))
                        elems011.value = elems011.value + 1;
                    if (pointsOfInterestInNearby[i].category.startsWith('012'))
                        elems012.value = elems012.value + 1;
                    if (pointsOfInterestInNearby[i].category.startsWith('013'))
                        elems013.value = elems013.value + 1;
                    if (pointsOfInterestInNearby[i].category.startsWith('014'))
                        elems014.value = elems014.value + 1;
                    if (pointsOfInterestInNearby[i].category.startsWith('015'))
                        elems015.value = elems015.value + 1;
                    if (pointsOfInterestInNearby[i].category.startsWith('016'))
                        elems016.value = elems016.value + 1;
                    if (pointsOfInterestInNearby[i].category.startsWith('017'))
                        elems017.value = elems017.value + 1;
                    if (pointsOfInterestInNearby[i].category.startsWith('018'))
                        elems018.value = elems018.value + 1;
                    if (pointsOfInterestInNearby[i].category.startsWith('019'))
                        elems019.value = elems019.value + 1;
                }
            }
        }
        var total = elems001.value + elems002.value + elems003.value + elems004.value;
        setTotalPoiNumber(total);
        percentage001 = Math.round((elems001.value / total) * 100.00);
        percentage002 = Math.round((elems002.value / total) * 100.00);
        percentage003 = Math.round((elems003.value / total) * 100.00);
        percentage004 = Math.round((elems004.value / total) * 100.00);
        var dataArray = [elems001, elems002, elems003, elems004, elems005, elems006, elems007, elems008,
            elems009, elems010, elems011, elems012, elems013, elems014, elems015, elems016, elems017, elems018, elems019];
        setChartData({
            labels: [
                t('poi.community.001_DIM_COM_pc', {absolut: elems001.value, percentage: percentage001 }),
                t('poi.community.002_DIM_DES_pc', {absolut: elems002.value, percentage: percentage002 }),
                t('poi.community.003_DIM_ECO_pc', {absolut: elems003.value, percentage: percentage003 }),
                t('poi.community.004_DIM_ENV_pc', {absolut: elems004.value, percentage: percentage004 })
            ],
            datasets: sortDataArray(dataArray)
        });
    }

    function fetchData() {
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': localStorage.getItem('token')
            }
        };
        showLoader();
        fetch(process.env.REACT_APP_API_URL + '/dedomena-data-management/report?dataset=' + dataset + '&egris=' + egris + '&poiDistance=' + poiDistance + '&poiPopularity=' + poiPopularity, requestOptions)
            .then(async response => {
                const isJson = response.headers.get('content-type')?.includes('application/json');
                const data = isJson && await response.json();
                // check for error response
                if (!response.ok) {
                    // get error message from body or default to response status
                    const error = (data && data.message) || response.status;
                    return Promise.reject(error);
                } else {
                    setReportData(data);
                    setMapGeometryBounds(data.mapGeometry.geometryGeoJsonBounds);
                    setZonenGeoJson(createZonenFeatures(data.mapGeometry.zonesGeoJson));
                    if (data.pointsOfInterestInNearby != null && data.pointsOfInterestInNearby.length > 0) {
                        setMapGeometryBoundsPoi(calculateBoundsForPoi(data.pointsOfInterestInNearby));
                        fillChartData(data.pointsOfInterestInNearby);
                    }
                }
                hideLoader();
            })
            .catch(error => {
                console.log(error);
                M.toast({
                    html: '<n:i class="material-icons">error</n:i> Cannot retrieve report data. Try again later.',
                    classes: 'red lighten-1 white-text'
                });
                hideLoader();
            });
    }

    useEffect(() => {
        showLoader();
        fetchData();
    }, [poiPopularity, poiDistance]);

    let renderedPage = Auth("ROLE_USER");

    function generateTextGebaudes(gebaudes) {
        var result  = '';
        if (gebaudes != null && gebaudes.length > 0) {
            result += gebaudes.map(gb => gb.egid) ;
            result = result.replaceAll(",", ", ");
        }
        return result;
    }

    function handleClickEgid(egid) {
        var elem = document.getElementById(egid + "gebaudeTable");
        if  (elem.style.visibility === 'visible') {
            elem.style.visibility = 'collapse';
        }
        else
            elem.style.visibility = 'visible';
        return undefined;
    }

    function handleClickZoneTid(tid) {
        var elem = document.getElementById(tid + "zoneReferenceTable");
        if  (elem.style.visibility === 'visible') {
            elem.style.visibility = 'collapse';
        }
        else
            elem.style.visibility = 'visible';
        return undefined;
    }

    function GeometryChangeView({bounds, zoom, lockMapType}) {
        const map = useMap();
        if (bounds != null && bounds.length > 0) {
            if (lockMapType === "buildings" && !lockMapBuildings) {
                map.fitBounds(bounds);
            }
            if (lockMapType === "zones" && !lockMapZones) {
                map.fitBounds(bounds);
            } else if (lockMapType == null) {
                map.fitBounds(bounds);
            }
        }
    }

    function calculateGebaudeTyp(gebaudetyp) {
        switch (gebaudetyp) {
            case 'housing': return 'Wohnen';
            case 'mixed': return 'Mischnutzung';
            case 'commercial': return 'Gewerbe';
            case 'other': return 'Andere';
        }
    }

    function calculateKanton(dataset) {
        switch(dataset) {
            case 'CH-BL': return 'Basel-Landschaft';
            case 'CH-BE': return 'Bern';
            case 'CH-BS': return 'Basel-Stadt';
            case 'CH-SO': return 'Solothurn';
            case 'CH-ZH': return 'Zürich';
            default: return dataset;
        }
    }

    const onEachBuilding = (feature, layer) =>{
        layer.on('mouseover', function (e) {
            setMapBuildingsInfo("EGID: " + feature.properties.egid);
            layer.setStyle({fillColor: '#fff'});
        });
        layer.on('mouseout', function (e) {
            setMapBuildingsInfo('');
            layer.setStyle({fillColor: '#8c9eff'});
        });
    }

    const onEachZone = (feature, layer) => {
        layer.on('mouseover', function (e) {
            setMapZonesInfo("Kommunal: " + feature.properties.kommTyp);
            layer.setStyle({fillColor: '#9575cd'});
        });
        layer.on('mouseout', function (e) {
            setMapZonesInfo('');
            layer.setStyle({fillColor: '#8c9eff'});
        });
    }

    function numberWithCommas(x) {
        if (x == null)
            return 'N/A';
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    if (renderedPage !== "") {
        return <Navigate replace to={renderedPage} />;
    } else {
        const user = jwtDecode(payload);
        return (
            <div id={"report-body"}>
                <div className={"row"}>
                    <Menu callBack={setPayload}/>
                </div>
                <div className={"row"}>
                    <h4 className={"col s12"}>{t('report.title')}</h4>
                    <h5 className={"col s12"}>{reportData != null && reportData.gebaude != null && reportData.gebaude.length > 0 && reportData.gebaude[0].gemeinde}, {calculateKanton(dataset)}, {reportData != null && reportData.zonen != null && reportData.zonen.length > 0 && ("Zone: " + reportData.zonen[0].kommunal)}, EGRIS: {egris}</h5>
                </div>
                <div className={"row"}>
                    <div className={"col s12 m12 l12 xl12"}>
                        <div className={"row"}>
                            <div className={"col s6"}>
                                <div className={"map-overlay"} onClick={e => lockMapBuildingsChanged(!lockMapBuildings)}>
                                    { !lockMapBuildings && <span className={"material-icons black-text"} style={{"cursor": "pointer"}} onClick={e => lockMapBuildingsChanged(!lockMapBuildings)}>gps_not_fixed</span> }
                                    { lockMapBuildings && <span className={"material-icons red-text"} style={{"cursor": "pointer"}} onClick={e => lockMapBuildingsChanged(!lockMapBuildings)}>gps_fixed</span> }
                                </div>
                                <div className='my-map-features'>
                                    <div className='my-map-features__map-container'>
                                        {mapBuildingsInfo != null && mapBuildingsInfo.length > 0 && <div id="map-buildings-info">{mapBuildingsInfo}</div> }
                                        {/*{mapBuildingsInfo == null || mapBuildingsInfo.length == 0 && <div id="map-buildings-info-void">---</div> }*/}
                                        <MapContainer className={"map-report-box col s12"} bounds={mapGeometryBounds} zoom={13} center={[47.3382723372246, 7.33631501368192]} scrollWheelZoom={true}>
                                            <TileLayer attribution='&copy; <a href="https://www.geo.admin.ch/">geo.admin.ch das Geoportal des Bundes</a> contributors'
                                                       url="https://wmts.geo.admin.ch/1.0.0/ch.swisstopo.swissimage/default/current/3857/{z}/{x}/{y}.jpeg"
                                                       opacity={0.7}
                                                       maxZoom={30}
                                                       maxNativeZoom={18}
                                            />
                                            <GeometryChangeView bounds={mapGeometryBounds} lockMapType={"buildings"}/>
                                            {reportData != null && reportData.zoneGrenzabstandDTO.kaArea != null && reportData.zoneGrenzabstandDTO.kaArea > 100 && reportData.zoneGrenzabstandDTO.kaGeometry != null && reportData.zoneGrenzabstandDTO.kaGeometry.length > 0 &&
                                                <GeoJSON key='geometry' data={JSON.parse(reportData.zoneGrenzabstandDTO.kaGeometry)}
                                                         fillColor={'#e3f2fd'} fillOpacity={.0} stroke={true} weight={1} color={"#cc0039"}
                                                />
                                            }
                                            {reportData != null && reportData.zoneGrenzabstandDTO.kaArea != null && reportData.zoneGrenzabstandDTO.kaArea > 100 && reportData.zoneGrenzabstandDTO.gaGeometry != null && reportData.zoneGrenzabstandDTO.gaGeometry.length > 0 &&
                                                <GeoJSON key='geometry' data={JSON.parse(reportData.zoneGrenzabstandDTO.gaGeometry)}
                                                         fillColor={'#e3f2fd'} fillOpacity={.0} stroke={true} weight={1} color={"#ff7f7f"}
                                                />
                                            }
                                            {reportData != null && reportData.mapGeometry != null && reportData.mapGeometry.geometryGeoJson != null && reportData.mapGeometry.geometryGeoJson.length > 0 &&
                                                <GeoJSON key='geometry' data={JSON.parse(reportData.mapGeometry.geometryGeoJson)}
                                                         fillColor={'#e3f2fd'} fillOpacity={.4} stroke={true} weight={0.8} color={"#bbdefb"}
                                                />
                                            }
                                            {reportData != null && reportData.mapGeometry != null && reportData.mapGeometry.featureGeoJson != null && reportData.mapGeometry.featureGeoJson.length > 0  &&
                                                <GeoJSON key='feature-group' data={JSON.parse(reportData.mapGeometry.featureGeoJson)} onEachFeature={onEachBuilding}
                                                         fillColor={'#8c9eff'} fillOpacity={.6} stroke={true} weight={0.8} color={"#000"}/>
                                            }
                                            {reportData != null && reportData.mapGeometry != null && reportData.mapGeometry.pointsGeoJson != null && reportData.mapGeometry.pointsGeoJson.length > 0 && reportData.mapGeometry.pointsGeoJson.map((point, l) => {
                                                var point = JSON.parse(point);
                                                if (point != null && point.coordinates != null) {
                                                    return(
                                                        <Marker key={'points' + l} icon={reportIcon} position={point.coordinates}></Marker>
                                                    )
                                                }
                                            })}
                                        </MapContainer>
                                    </div>
                                </div>
                            </div>
                            {/*</div>*/}
                            {/*<div className={"row"}>*/}
                            <div className={"col s6"}>
                                <div className={"map-overlay"} onClick={e => lockMapZonesChanged(!lockMapZones)}>
                                    { !lockMapZones && <span className={"material-icons black-text"} style={{"cursor": "pointer"}} onClick={e => lockMapZonesChanged(!lockMapZones)}>gps_not_fixed</span> }
                                    { lockMapZones && <span className={"material-icons red-text"} style={{"cursor": "pointer"}} onClick={e => lockMapZonesChanged(!lockMapZones)}>gps_fixed</span> }
                                </div>
                                <div className='my-map-zones'>
                                    <div className='my-map-zones__map-container'>
                                        {mapZonesInfo != null && mapZonesInfo.length > 0 && <div id="map-zones-info">{mapZonesInfo}</div> }
                                        {/*{mapZonesInfo == null || mapZonesInfo.length == 0 && <div id="map-zones-info-void">---</div> }*/}
                                        <MapContainer className={"map-report-box col s12"} bounds={mapZonesBounds} zoom={13} center={[47.3382723372246, 7.33631501368192]} scrollWheelZoom={true}>
                                            <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                       url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                       opacity={0.7}
                                                       maxZoom={22}
                                            />
                                            <GeometryChangeView bounds={mapZonesBounds} lockMapType={"zones"}/>
                                            {reportData != null && reportData.mapGeometry != null && reportData.mapGeometry.geometryGeoJson != null && reportData.mapGeometry.geometryGeoJson.length > 0 &&
                                                <GeoJSON key='geometryzone' data={JSON.parse(reportData.mapGeometry.geometryGeoJson)}
                                                         fillColor={'#ffea00'} fillOpacity={.0} stroke={true} weight={1.2} color={"#000"}
                                                />
                                            }
                                            {reportData != null && reportData.mapGeometry != null && reportData.mapGeometry.featureGeoJson != null && reportData.mapGeometry.featureGeoJson.length > 0  &&
                                                <GeoJSON key='feature-group' data={JSON.parse(reportData.mapGeometry.featureGeoJson)}
                                                         fillOpacity={.0} stroke={true} weight={0.8} color={"#000"} fillColor={'#6200ea'}/>
                                            }
                                            {zonenGeoJson != null && zonenGeoJson.length > 0 &&
                                                <GeoJSON key={'zones'} icon={reportIcon} data={JSON.parse(zonenGeoJson)} stroke={true} weight={0.8} fillColor={"#8c9eff"} fillOpacity={0.6} stroke={true} weight={"0.8"} color={"#304ffe"}
                                                         onEachFeature={onEachZone}></GeoJSON>
                                            })
                                        </MapContainer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"col s12 m12 l12 xl12"}>
                        <div className={"row"}>
                            <h6 className={"col s12"}>Nutzungsmasse (Maximum)</h6>
                            <div className={"col s12"}>
                                <table className={"responsive-table"}>
                                    <thead>
                                        <tr className={"blue-grey lighten-1"}>
                                                <td className={"white-text report-table-data"}>Parzellenfläche</td>
                                                <td className={"white-text report-table-data"}>Grünflächenziffer</td>
                                                <td className={"white-text report-table-data"}>Freiflächenziffer</td>
                                                <td className={"white-text report-table-data"}>Ausnutzungsziffer</td>
                                                <td className={"white-text report-table-data"}>Bebauungsziffer</td>
                                                <td className={"white-text report-table-data"}>Baumassenziffer</td>
                                                <td className={"white-text report-table-data"}>VG</td>
                                                <td className={"white-text report-table-data"}>DG</td>
                                                <td className={"white-text report-table-data"}>Dachfläche pro VG</td>
                                                <td className={"white-text report-table-data"}>UG</td>
                                                <td className={"white-text report-table-data"}>Fassadenhöhe</td>
                                                <td className={"white-text report-table-data"}>Gebaudehöhe</td>
                                                <td className={"white-text report-table-data"}>Max. Gesamthöhe</td>
                                                <td className={"white-text report-table-data highlighted-column"}>Max. Nutzungspotenzial</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                                <td className={"report-table-data"}>{reportData != null && numberWithCommas(reportData.nutzungmasseSoll.parzellenFlache)} m2</td>
                                                <td className={"report-table-data"}>{reportData != null && ((reportData.nutzungmasseSoll.grunFlachenZiffer !== 'N/A' && Math.round(reportData.nutzungmasseSoll.grunFlachenZiffer * 100)) || "N/A")}% ({reportData != null && ((reportData.nutzungmasseSoll.grunFlachenZifferM2 != null && numberWithCommas(reportData.nutzungmasseSoll.grunFlachenZifferM2.split('.')[0])) || 'N/A') } m2)</td>
                                                <td className={"report-table-data"}>{reportData != null && numberWithCommas(reportData.nutzungmasseSoll.freiflachenZiffer)} m2</td>
                                                <td className={"report-table-data"}>{reportData != null && numberWithCommas(reportData.nutzungmasseSoll.ausnutzungsziffer)}</td>
                                                <td className={"report-table-data"}>{reportData != null && numberWithCommas(reportData.nutzungmasseSoll.bebauungsziffer)}</td>
                                                <td className={"report-table-data"}>{reportData != null && numberWithCommas(reportData.nutzungmasseSoll.baumassenziffer)}</td>
                                                <td className={"report-table-data"}>{reportData != null && reportData.nutzungmasseSoll.vg}</td>
                                                <td className={"report-table-data"}>{reportData != null && reportData.nutzungmasseSoll.dg}</td>
                                                <td className={"report-table-data"}>{reportData != null && reportData.nutzungmasseSoll.maxDachFlacheProVG}</td>
                                                <td className={"report-table-data"}>{reportData != null && reportData.nutzungmasseSoll.ug}</td>
                                                <td className={"report-table-data"}>{reportData != null && reportData.nutzungmasseSoll.maxHoheInMFH} m</td>
                                                <td className={"report-table-data"}>{reportData != null && reportData.nutzungmasseSoll.gebaudeHoehe} m</td>
                                                <td className={"report-table-data"}>{reportData != null && reportData.nutzungmasseSoll.maxHoheInMGH} m</td>
                                                <td className={"report-table-data highlighted-cell"}>{reportData != null && Math.round(reportData.nutzungmasseSoll.potBruttogeschosszifferPc*100)}% ({reportData != null && ((reportData.nutzungmasseSoll.potBruttogeschosszifferM2 != null && numberWithCommas(reportData.nutzungmasseSoll.potBruttogeschosszifferM2.split('.')[0])) || 'N/A')} m2)</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className={"row"}>
                            <h6 className={"col s12"}>Nutzungsmasse (Aktuell)</h6>
                            <div className={"col s12"}>
                                <table className={"responsive-table"}>
                                    <thead>
                                        <tr className={"blue-grey lighten-1"}>
                                            <td className={"white-text report-table-data"}>Parzellenfläche</td>
                                            <td className={"white-text report-table-data"}>Bebaute Fläche</td>
                                            <td className={"white-text report-table-data"}>Freie Fläche</td>
                                            <td className={"white-text report-table-data"}>Verfügbare Fläche</td>
                                            <td className={"white-text report-table-data"}>Total Volumen</td>
                                            <td className={"white-text report-table-data"}>BGF</td>
                                            <td className={"white-text report-table-data highlighted-column"}>Ausnutzungsreserve</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className={"report-table-data"}>{reportData != null && ((reportData.nutzungmasseIst.parzellenFlache != null && numberWithCommas(reportData.nutzungmasseIst.parzellenFlache.split('.')[0])) || 'N/A')} m2</td>
                                            <td className={"report-table-data"}>{reportData != null && numberWithCommas(reportData.nutzungmasseIst.bebauteFlache)} m2</td>
                                            <td className={"report-table-data"}>{reportData != null && ((reportData.nutzungmasseIst.freiflachenZiffer != null && numberWithCommas(reportData.nutzungmasseIst.freiflachenZiffer.split('.')[0])) || 'N/A')} m2 ({reportData != null && Math.round(reportData.nutzungmasseIst.freiflachenZifferPc*100)} %)</td>
                                            <td className={"report-table-data"}>{reportData != null && ((reportData.nutzungmasseIst.verfugbareFlachenZiffer != null && numberWithCommas(reportData.nutzungmasseIst.verfugbareFlachenZiffer.split('.')[0])) || 'N/A')} m2 ({reportData != null && Math.round(reportData.nutzungmasseIst.verfugbareFlachenZifferPc*100)} %)</td>
                                            <td className={"report-table-data"}>{reportData != null && numberWithCommas(reportData.nutzungmasseIst.totalVolumen)} m3</td>
                                            <td className={"report-table-data"}>{reportData != null && numberWithCommas(reportData.nutzungmasseIst.bgf)} m2</td>
                                            <td className={"report-table-data highlighted-cell"}>{reportData != null && Math.round(reportData.nutzungmasseIst.bruttogeschosszifferPc*100)} % ({reportData != null && ((reportData.nutzungmasseIst.bruttogeschosszifferM2 != null && numberWithCommas(reportData.nutzungmasseIst.bruttogeschosszifferM2.split('.')[0])) || 'N/A') } m2)</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className={"row"}>
                            <h6 className={"col s12"}>Grundstückinformationen</h6>
                            <div className={"col s12"}>
                                <table className={"responsive-table"}>
                                    <thead>
                                    <tr className={"blue-grey lighten-1"}>
                                        <td className={"white-text report-table-data"}>EGRIS_EGRI</td>
                                        <td className={"white-text report-table-data"}>Grundstücknummer</td>
                                        <td className={"white-text report-table-data"}>Gemeinde</td>
                                        <td className={"white-text report-table-data"}>Parzellenfläche</td>
                                        <td className={"white-text report-table-data"}>kGA</td>
                                        <td className={"white-text report-table-data"}>gGA</td>
                                        <td className={"white-text report-table-data"}>kGA Fläche</td>
                                        <td className={"white-text report-table-data"}>GA Fläche</td>
                                        {/*<td className={"white-text report-table-data"}>Bebaute Fläche</td>*/}
                                        <td className={"white-text report-table-data"}>Gebäude</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className={"report-table-data"}>{reportData != null && reportData.grundstuckinformationen.egris}</td>
                                        <td className={"report-table-data"}>{reportData != null && reportData.grundstuckinformationen.grundstucknummer}</td>
                                        <td className={"report-table-data"}>{reportData != null && reportData.grundstuckinformationen.gemeinde}</td>
                                        <td className={"report-table-data"}>{reportData != null && ((reportData.grundstuckinformationen.parzellenflache != null && numberWithCommas(reportData.grundstuckinformationen.parzellenflache.split('.')[0])) || 'N/A') } m2</td>
                                        <td className={"report-table-data"}>{reportData != null && ((reportData.zoneGrenzabstandDTO.klGrenzabstand != null && numberWithCommas(reportData.zoneGrenzabstandDTO.klGrenzabstand)) || 'N/A')} m</td>
                                        <td className={"report-table-data"}>{reportData != null && ((reportData.zoneGrenzabstandDTO.grGrenzabstand != null && numberWithCommas(reportData.zoneGrenzabstandDTO.grGrenzabstand)) || 'N/A')} m</td>
                                        <td className={"report-table-data"}>{reportData != null && ((reportData.zoneGrenzabstandDTO.kaArea != null && numberWithCommas(reportData.zoneGrenzabstandDTO.kaArea)) || 'N/A')} m2</td>
                                        <td className={"report-table-data"}>{reportData != null && ((reportData.zoneGrenzabstandDTO.combinedArea != null && numberWithCommas(reportData.zoneGrenzabstandDTO.combinedArea)) || 'N/A')} m2</td>
                                        {/*<td className={"report-table-data"}>{reportData != null && reportData.grundstuckinformationen.bebauteflache.split('.')[0]} m2</td>*/}
                                        <td className={"report-table-data"}>{reportData != null && reportData.grundstuckinformationen.gebaudeCount} ({reportData != null && generateTextGebaudes(reportData.grundstuckinformationen.gebaudes)})</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className={"row"}>
                            <h6 className={"col s12"}>Bestehende Gebäude</h6>
                            <div className={"col s12"}>
                                <table className={"responsive-table"}>
                                    <thead>
                                    <tr className={"blue-grey lighten-1"}>
                                        <td className={"white-text report-table-data"}>EGID</td>
                                        <td className={"white-text report-table-data"}>Gebäudetyp</td>
                                        <td className={"white-text report-table-data"}>Gebäudeklasse</td>
                                        <td className={"white-text report-table-data"}>Bezeichnung</td>
                                        <td className={"white-text report-table-data"}>Baujahr</td>
                                        <td className={"white-text report-table-data"}>Volumen</td>
                                        <td className={"white-text report-table-data"}>Grundriss</td>
                                        <td className={"white-text report-table-data"}>Höhe*</td>
                                        <td className={"white-text report-table-data"}>Geschosse</td>
                                        <td className={"white-text report-table-data"}>UG*</td>
                                        <td className={"white-text report-table-data highlighted-column"}>Geschosspotenzial</td>
                                        <td className={"white-text report-table-data"}>Wohngeschosse</td>
                                        <td className={"white-text report-table-data"}>Wohnfläche</td>
                                        <td className={"white-text report-table-data"}>Adresse</td>
                                    </tr>
                                    </thead>
                                        {reportData != null && reportData.grundstuckinformationen != null && reportData.grundstuckinformationen.gebaudes != null && reportData.grundstuckinformationen.gebaudes.length > 0 && reportData.grundstuckinformationen.gebaudes.map((gebaude, i) => {
                                            return(
                                                <tbody key={gebaude.egid}>
                                                    <tr>
                                                        <td className={"report-table-data"}><a style={{cursor: 'pointer'}} className={"valign-wrapper"} onClick={() => handleClickEgid(gebaude.egid)}>{gebaude.egid}  <i className={"material-icons"} style={{fontSize: '1.1em'}}>expand_more</i></a></td>
                                                        <td className={"report-table-data"}>{calculateGebaudeTyp(gebaude.gebaudetyp)}</td>
                                                        <td className={"report-table-data"}>{gebaude.gebaudeKlasse}</td>
                                                        <td className={"report-table-data"}>{gebaude.gebaudetypEz}</td>
                                                        <td className={"report-table-data"}>{gebaude.baujahr}</td>
                                                        <td className={"report-table-data"}>{numberWithCommas(gebaude.volumen)} m3</td>
                                                        <td className={"report-table-data"}>{numberWithCommas(gebaude.grundriss.split('.')[0])} m2</td>
                                                        <td className={"report-table-data"}>{gebaude.hohe} m*</td>
                                                        <td className={"report-table-data"}>{gebaude.geschosse}</td>
                                                        <td className={"report-table-data"}>{gebaude.ug} *</td>
                                                        <td className={"report-table-data highlighted-cell"}>{gebaude.geschossPot}</td>
                                                        <td className={"report-table-data"}>{gebaude.wohnunggeschoss}</td>
                                                        <td className={"report-table-data"}>{numberWithCommas(gebaude.wohnflache.split('.')[0])} m2</td>
                                                        <td className={"report-table-data"}>
                                                            <table><tbody>
                                                            { gebaude.addresse != null && gebaude.addresse.map((addresse, j) => {
                                                                    return (<tr className={"no-border no-padding"} key={"addr" + j}><td className={"no-border no-padding"}>{addresse}</td></tr>)
                                                                })
                                                            }
                                                            </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr id={gebaude.egid + "gebaudeTable"} style={{visibility: 'collapse'}}>
                                                        <td className={"white-text report-table-data"}></td>
                                                        <td colSpan={10}>
                                                            <h6 className={"col s12"}>Wohnungen</h6>
                                                            <table className={"responsive-table blue-grey lighten-5"}>
                                                                <thead>
                                                                <tr className={"blue-grey lighten-1"}>
                                                                    <td className={"white-text report-table-data"}>Ewid</td>
                                                                    <td className={"white-text report-table-data"}>Geschoss</td>
                                                                    <td className={"white-text report-table-data"}>Jahre</td>
                                                                    <td className={"white-text report-table-data"}>Ausstatung</td>
                                                                    <td className={"white-text report-table-data"}>Wohnfläche</td>
                                                                    <td className={"white-text report-table-data"}>Zimmer</td>
                                                                    <td className={"white-text report-table-data"}>Status</td>
                                                                    <td className={"white-text report-table-data"}></td>
                                                                </tr>
                                                                </thead>
                                                                {gebaude.wohnungen != null && gebaude.wohnungen.map((wohnung, j) => {
                                                                    return (
                                                                        <tbody key={wohnung.ewid}>
                                                                        <tr key={"wohn" + wohnung.ewid + "f" + wohnung.geschossFloor}>
                                                                            <td className={"report-table-data"}>{wohnung.ewid}</td>
                                                                            <td className={"report-table-data"}>{wohnung.geschossFloor} {wohnung.geschossWbez !== 'N/A' ? "(" + wohnung.geschossWbez + ")" : ""}</td>
                                                                            <td className={"report-table-data"}>{wohnung.jahre}</td>
                                                                            <td className={"report-table-data"}>{wohnung.ausstatungWkche === "true" ? "Küche, " : ""} {wohnung.ausstatungWmehrg === "true" ? "Duplex" : ""}</td>
                                                                            <td className={"report-table-data"}>{numberWithCommas(wohnung.wohnflacheM2.split('.')[0])} m2</td>
                                                                            <td className={"report-table-data"}>{wohnung.zimmer}</td>
                                                                            <td className={"report-table-data"}>{wohnung.status}</td>
                                                                        </tr>
                                                                        </tbody>
                                                                    )
                                                                })}
                                                            </table>
                                                        </td>
                                                        <td className={"white-text report-table-data"}></td>
                                                    </tr>
                                                </tbody>
                                            )
                                        })}
                                </table>
                            </div>
                        </div>
                        <div className={"row"}>
                            <h6 className={"col s12"}>Zonen</h6>
                            <div className={"col s12"}>
                                <table className={"responsive-table"}>
                                    <thead>
                                        <tr className={"blue-grey lighten-1"}>
                                            <td className={"white-text report-table-data"}>Anteil</td>
                                            <td className={"white-text report-table-data"}>Anteil</td>
                                            {/*<td className={"white-text report-table-data"}>Anteil Exkl.</td>*/}
                                            {/*<td className={"white-text report-table-data"}>HN Code</td>*/}
                                            <td className={"white-text report-table-data"}>Kommunal</td>
                                            <td className={"white-text report-table-data"}>Komm. Code</td>
                                            {/*<td className={"white-text report-table-data"}>HN</td>*/}
                                            <td className={"white-text report-table-data"}>Kantonal</td>
                                            <td className={"white-text report-table-data"}>Kant. Code</td>
                                            <td className={"white-text report-table-data"}>Quelle</td>
                                            <td className={"white-text report-table-data"}>Publiziert</td>
                                            <td className={"white-text report-table-data"}></td>
                                        </tr>
                                    </thead>
                                    {reportData != null && reportData.zonen != null && reportData.zonen.map((zone, i) => {
                                        return(<tbody key={"zone" + i}>
                                        <tr>
                                            <td className={"report-table-data"}>
                                                {zone.reference != null && zone.reference.tid != null && <a style={{cursor: 'pointer'}} className={"valign-wrapper"} onClick={() => handleClickZoneTid(zone.tid)}>{(zone.anteil*100).toFixed(2)} %  <i className={"material-icons"} style={{fontSize: '1.1em'}}>expand_more</i></a>}
                                                {(zone.reference == null || zone.reference.tid == null) && <p>{(zone.anteil*100).toFixed(2)} % </p> }
                                            </td>
                                            <td className={"report-table-data"}>{numberWithCommas(zone.anteilM2.split('.')[0])} m2</td>
                                            {/*<td className={"report-table-data"}>{zone.anteilExklusive}</td>*/}
                                            {/*<td className={"report-table-data"}>{zone.hnCode}</td>*/}
                                            <td className={"report-table-data"}>{zone.kommunal}</td>
                                            <td className={"report-table-data"}>{zone.kommCode}</td>
                                            {/*<td className={"report-table-data"}>{zone.kommCodeHn}</td>*/}
                                            <td className={"report-table-data"}>{zone.kantonal}</td>
                                            <td className={"report-table-data"}>{zone.kantCode}</td>
                                            <td className={"report-table-data"}>{zone.quelle}</td>
                                            <td className={"report-table-data"}>{zone.publiziert}</td>
                                        </tr>
                                        {zone.reference != null && zone.reference.tid != null &&
                                            <tr id={zone.tid + "zoneReferenceTable"} style={{visibility: 'collapse'}}>
                                                <td className={"white-text report-table-data"}></td>
                                                <td colSpan={11}>
                                                    <h6 className={"col s12"}>Zone reference</h6>
                                                    <table className={"col s12 responsive-table blue-grey lighten-5"}>
                                                        <thead>
                                                        <tr className={"blue-grey lighten-1"}>
                                                            { zone.reference.vollgeschosse != null && <td className={"white-text report-table-data"}>Vollgeschosse</td>}
                                                            { zone.reference.dachgeschosse != null && <td className={"white-text report-table-data"}>Dachgeschosse</td>}
                                                            { zone.reference.dgNotInAz != null && <td className={"white-text report-table-data"}>DG nicht in AZ</td>}
                                                            { zone.reference.maxDachflaecheProVg != null && <td className={"white-text report-table-data"}>Max. Dachfläche pro VG</td>}
                                                            { zone.reference.untergeschosse != null && <td className={"white-text report-table-data"}>Untergeschosse</td>}
                                                            { zone.reference.maxUntergeschossflaecheProVg != null &&<td className={"white-text report-table-data"}>Max. Untergeschossfläche pro VG</td>}
                                                            { zone.reference.ausnutzungszifferAz != null && <td className={"white-text report-table-data"}>Ausnutzungsziffer (AZ)</td>}
                                                            { zone.reference.ausnutzungszifferAzMin != null && <td className={"white-text report-table-data"}>Min. Ausnutzungsziffer</td>}
                                                            { zone.reference.bebauungszifferBz != null && <td className={"white-text report-table-data"}>Bebauungsziffer</td>}
                                                            { zone.reference.baumassenzifferBmz != null && <td className={"white-text report-table-data"}>Baumassenziffer</td>}
                                                            { zone.reference.gruenflaechenzifferGz != null && <td className={"white-text report-table-data"}>Grünflächenziffer</td>}
                                                            { zone.reference.freiflaechenzifferFz != null && <td className={"white-text report-table-data"}>Freiflächenziffer</td>}
                                                            { zone.reference.fassadenhoehe != null && <td className={"white-text report-table-data"}>Fassadenhöhe</td>}
                                                            { zone.reference.gebaeudehoehe != null && <td className={"white-text report-table-data"}>Gebäudehöhe</td>}
                                                            { zone.reference.firsthoehe != null && <td className={"white-text report-table-data"}>Firsthöhe</td>}
                                                            { zone.reference.fullHeight != null && <td className={"white-text report-table-data"}>Gesamthöhe</td>}
                                                            { zone.reference.sockelgeschosshoehe != null &&<td className={"white-text report-table-data"}>Sockelgeschosshöhe</td>}
                                                            { zone.reference.kniestockhoehe != null && <td className={"white-text report-table-data"}>Kniestockhöhe</td>}
                                                            { zone.reference.gebaeudelaenge != null && <td className={"white-text report-table-data"}>Gebäudelange</td>}
                                                            { zone.reference.gebaeudebreite != null && <td className={"white-text report-table-data"}>Gebäudebreite</td>}
                                                            { zone.reference.mehrlaengenzuschlagMlz != null && <td className={"white-text report-table-data"}>Mehrlängenzuschlag MLZ</td>}
                                                            { zone.reference.mlzAbM != null && <td className={"white-text report-table-data"}>MLZ ab m</td>}
                                                            { zone.reference.mlzPc != null && <td className={"white-text report-table-data"}>MLZ Procent</td>}
                                                            { zone.reference.mehrbreitenzuschlagMbz != null && <td className={"white-text report-table-data"}>Mehrbreitenzuschlag MBZ</td>}
                                                            { zone.reference.mbzAbM != null && <td className={"white-text report-table-data"}>MBZ ab m</td>}
                                                            { zone.reference.mbzPc != null && <td className={"white-text report-table-data"}>MBZ Prozent</td>}
                                                            { zone.reference.klGrenzabstand != null && <td className={"white-text report-table-data"}>Kl. Grenzabstand</td>}
                                                            { zone.reference.grGrenzabstand != null && <td className={"white-text report-table-data"}>Gr. Grenzabstand</td>}
                                                            { zone.reference.bemerkunge != null && <td className={"white-text report-table-data"}>Bemerkunge</td>}
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                                <tr key={"zoneReference" + zone.reference.tid}>
                                                                    { zone.reference.vollgeschosse != null && <td className={"report-table-data"}>{zone.reference.vollgeschosse}</td>}
                                                                    { zone.reference.dachgeschosse != null && <td className={"report-table-data"}>{zone.reference.dachgeschosse}</td> }
                                                                    { zone.reference.dgNotInAz != null && <td className={"report-table-data"}>{zone.reference.dgNotInAz}</td> }
                                                                    { zone.reference.maxDachflaecheProVg != null && <td className={"report-table-data"}>{zone.reference.maxDachflaecheProVg} m2</td> }
                                                                    { zone.reference.untergeschosse != null && <td className={"report-table-data"}>{zone.reference.untergeschosse}</td> }
                                                                    { zone.reference.maxUntergeschossflaecheProVg != null && <td className={"report-table-data"}>{zone.reference.maxUntergeschossflaecheProVg} m2</td>}
                                                                    { zone.reference.ausnutzungszifferAz != null && <td className={"report-table-data"}>{(zone.reference.ausnutzungszifferAz*100).toFixed(2)} %</td>}
                                                                    { zone.reference.ausnutzungszifferAzMin != null && <td className={"report-table-data"}>{(zone.reference.ausnutzungszifferAzMin*100).toFixed(2)} %</td>}
                                                                    { zone.reference.bebauungszifferBz != null && <td className={"report-table-data"}>{(zone.reference.bebauungszifferBz*100).toFixed(2)} %</td>}
                                                                    { zone.reference.baumassenzifferBmz != null && <td className={"report-table-data"}>{(zone.reference.baumassenzifferBmz*100).toFixed(2)} %</td>}
                                                                    { zone.reference.gruenflaechenzifferGz != null && <td className={"report-table-data"}>{(zone.reference.gruenflaechenzifferGz*100).toFixed(2)} %</td>}
                                                                    { zone.reference.freiflaechenzifferFz != null && <td className={"report-table-data"}>{(zone.reference.freiflaechenzifferFz*100).toFixed(2)} %</td>}
                                                                    { zone.reference.fassadenhoehe != null && <td className={"report-table-data"}>{zone.reference.fassadenhoehe} m.</td>}
                                                                    { zone.reference.gebaeudehoehe != null && <td className={"report-table-data"}>{zone.reference.gebaeudehoehe} m.</td>}
                                                                    { zone.reference.firsthoehe != null && <td className={"report-table-data"}>{zone.reference.firsthoehe} m.</td> }
                                                                    { zone.reference.fullHeight != null && <td className={"report-table-data"}>{zone.reference.fullHeight} m.</td>}
                                                                    { zone.reference.sockelgeschosshoehe != null && <td className={"report-table-data"}>{zone.reference.sockelgeschosshoehe} m.</td>}
                                                                    { zone.reference.kniestockhoehe != null && <td className={"report-table-data"}>{zone.reference.kniestockhoehe} m.</td>}
                                                                    { zone.reference.gebaeudelaenge != null && <td className={"report-table-data"}>{zone.reference.gebaeudelaenge} m.</td>}
                                                                    { zone.reference.gebaeudebreite != null && <td className={"report-table-data"}>{zone.reference.gebaeudebreite} m.</td>}
                                                                    { zone.reference.mehrlaengenzuschlagMlz != null && <td className={"report-table-data"}>{zone.reference.mehrlaengenzuschlagMlz}</td>}
                                                                    { zone.reference.mlzAbM != null && <td className={"report-table-data"}>{zone.reference.mlzAbM} m.</td>}
                                                                    { zone.reference.mlzPc != null && <td className={"report-table-data"}>{(zone.reference.mlzPc*100).toFixed(2)} %</td>}
                                                                    { zone.reference.mehrbreitenzuschlagMbz != null && <td className={"report-table-data"}>{zone.reference.mehrbreitenzuschlagMbz}</td>}
                                                                    { zone.reference.mbzAbM != null && <td className={"report-table-data"}>{zone.reference.mbzAbM} m.</td>}
                                                                    { zone.reference.mbzPc != null && <td className={"report-table-data"}>{(zone.reference.mbzPc*100).toFixed(2)} %</td>}
                                                                    { zone.reference.klGrenzabstand != null && <td className={"report-table-data"}>{zone.reference.klGrenzabstand} m.</td>}
                                                                    { zone.reference.grGrenzabstand != null && <td className={"report-table-data"}>{zone.reference.grGrenzabstand} m.</td>}
                                                                    { zone.reference.bemerkunge != null && <td className={"report-table-data"}>{zone.reference.bemerkunge}</td>}
                                                                </tr>
                                                        </tbody>
                                                    </table>
                                                </td>

                                            </tr>
                                        }
                                        </tbody>)
                                    })}
                                </table>
                            </div>
                        </div>
                        <div className={"row"}>
                            <h6 className={"col s12"}>Weitere Informationen</h6>
                            <div className={"col s12"}>
                                <table className={"responsive-table"}>
                                    <thead>
                                    <tr className={"blue-grey lighten-1"}>
                                        <td className={"white-text report-table-data"}>Ort</td>
                                        <td className={"white-text report-table-data"}>Dokument</td>
                                        <td className={"white-text report-table-data"}>Link</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {reportData != null && reportData.weitereInformation != null && reportData.weitereInformation.map((aLink, k) => {
                                        return(<tr key={"link" + k}>
                                            <td className={"report-table-data"}>{aLink.ort}</td>
                                            <td className={"report-table-data"}>{aLink.theme}</td>
                                            <td className={"report-table-data"}>
                                                {aLink.link !== 'n/a' && <a href={aLink.link} target={"_blank"}>{aLink.link}</a>}
                                                {aLink.link === 'n/a' && <p>n/a</p>}
                                            </td>
                                        </tr>)
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className={"row"}>
                            <h6 className={"col s12"}>Mieter und Einrichtungen im Gebäude</h6>
                            <div className={"col s12"}>
                                <table className={"responsive-table"}>
                                    <thead>
                                    <tr className={"blue-grey lighten-1"}>
                                        <td className={"white-text report-table-data"}>POI Name</td>
                                        <td className={"white-text report-table-data"}>Category</td>
                                        <td className={"white-text report-table-data"}>Subcategory</td>
                                        <td className={"white-text report-table-data"}>POI typ</td>
                                        <td className={"white-text report-table-data"}>POI Adresse</td>
                                        <td className={"white-text report-table-data"}>Popularity</td>
                                        <td className={"white-text report-table-data"}>User rating</td>
                                        <td className={"white-text report-table-data"}>Website</td>
                                        <td className={"white-text report-table-data"}></td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {reportData != null && reportData.pointsOfInterest != null && reportData.pointsOfInterest.map((aPoint, k) => {
                                        return(<tr key={"aPoint" + k}>
                                            <td className={"report-table-data"}>{aPoint.name}</td>
                                            <td className={"report-table-data"}>{aPoint.category}</td>
                                            <td className={"report-table-data"}>{aPoint.subcategory}</td>
                                            <td className={"report-table-data"}>{aPoint.type}</td>
                                            <td className={"report-table-data"}>{aPoint.address}</td>
                                            <td className={"report-table-data"}>{aPoint.popularity}</td>
                                            <td className={"report-table-data"}>{aPoint.userRating}</td>
                                            <td className={"report-table-data"}>
                                                {aPoint.website !== 'null' && <a href={aPoint.website} target={"_blank"}>{aPoint.website}</a>}
                                                {aPoint.website === 'null' && <p>n/a</p>}
                                            </td>
                                            <td className={"report-table-data green-text"}>
                                                {aPoint.businessStatus === "OPERATIONAL" && <span className={"green-text"}>OPERATIV</span> }
                                                {aPoint.businessStatus === "CLOSED_TEMPORARILY" && <span className={"red-text"}>VORÜBERGEHEND GESCHLOSSEN</span> }
                                            </td>
                                        </tr>)
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className={"col s6"}>
                                <div className='my-map-poi'>
                                    <div className='my-map-poi__map-container'>
                                        {reportData != null &&
                                            <div id="map-poi-info">
                                                <div className={"row"}>
                                                    <h6 className={"col s12"}>Points of interest</h6>
                                                    <div className={"col s12 m12 l3 xl3"}>
                                                        <label className={"bottom-separator"}>Distanz:</label>
                                                        <select id={"poiDistanceSelector"} className={"browser-default"}
                                                                value={poiDistance}
                                                                onChange={e => handleFilterPoi("distance", e.target.value)}
                                                        >
                                                            <option value={500} key={"popDist500"}>&lt;= 500 m.</option>
                                                            <option value={100} key={"popDist100"}>&lt;= 100 m.</option>
                                                            <option value={200} key={"popDist200"}>&lt;= 200 m.</option>
                                                            <option value={300} key={"popDist300"}>&lt;= 300 m.</option>
                                                            <option value={400} key={"popDist400"}>&lt;= 400 m.</option>
                                                        </select>
                                                    </div>
                                                    <div className={"col s12 m12 l3 xl3"}>
                                                        <label className={"bottom-separator"}>Popularität:</label>
                                                        <select id={"poiPopularitySelector"} className={"browser-default"}
                                                                value={poiPopularity}
                                                                onChange={e => handleFilterPoi("popularity", e.target.value)}
                                                        >
                                                            <option value={0} key={"poiPop0"}>&gt;= 0</option>
                                                            <option value={1} key={"poiPop1"}>&gt;= 1</option>
                                                            <option value={2} key={"poiPop2"}>&gt;= 2</option>
                                                            <option value={3} key={"poiPop3"}>&gt;= 3</option>
                                                            <option value={4} key={"poiPop4"}>&gt;= 4</option>
                                                            <option value={5} key={"poiPop5"}>&gt;= 5</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <MapContainer className={"map-report-box col s12"} zoom={13} bounds={mapGeometryBoundsPoi} center={[47.3382723372246, 7.33631501368192]} scrollWheelZoom={true}>
                                            <TileLayer attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                opacity={0.7}
                                            />
                                            {reportData != null && reportData.mapGeometry != null && reportData.mapGeometry.geometryGeoJson != null && reportData.mapGeometry.geometryGeoJson.length > 0 &&
                                                <GeoJSON key='geometry' data={JSON.parse(reportData.mapGeometry.geometryGeoJson)}
                                                         fillColor={'#8c9eff'} fillOpacity={.8} stroke={true} weight={0.8} color={"#bbdefb"}
                                                />
                                            }
                                            <GeometryChangeView bounds={mapGeometryBoundsPoi}/>
                                            {reportData != null && reportData.pointsOfInterestInNearby != null && reportData.pointsOfInterestInNearby.length > 0 && reportData.pointsOfInterestInNearby.map((aPoint, k) => {
                                                if (aPoint != null && checkNotFiltered(aPoint))
                                                    return (<Marker  key={'markerPoi' + k} icon={poiIcon(aPoint.dimension)} position={[aPoint.latitude, aPoint.longitude]}>
                                                        <Popup>
                                                            <div className={"bold"}>{aPoint.name}</div>
                                                            <div>{aPoint.dimensionDe}</div>
                                                            <div>{t('poi.category.' + aPoint.category)}</div>
                                                            <div>{aPoint.subcategory}</div>
                                                            <div>{aPoint.address}</div>
                                                            <div>{aPoint.popularity}</div>
                                                            <div><a href={aPoint.website} target={"_blank"}>{aPoint.website}</a></div>
                                                            {aPoint.businessStatus === "OPERATIONAL" && <div className={"green-text"}>OPERATIV</div> }
                                                            {aPoint.businessStatus === "CLOSED_TEMPORARILY" && <div className={"red-text"}>VORÜBERGEHEND GESCHLOSSEN</div> }
                                                        </Popup>
                                                    </Marker>)
                                            })}
                                        </MapContainer>
                                    </div>
                                </div>
                            </div>
                            <div className={"col s6"}>
                                <div className={"row"}>
                                    <h6 className={"col s12"}>Mietermix im Quarter</h6>
                                    <label className={"col s12"}>Total POI #: {totalPoiNumber}</label>
                                </div>
                                <div className={"row"}>
                                    <div className={"s12"}>
                                        <Bar ref={chartRef} data={chartData} options={chartOptions} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {loader}
            </div>
        )
    }
}
export default Report;