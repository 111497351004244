import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import M from "materialize-css";

const UserListElement = (props) => {

    const [aUser] = useState(props.item);
    const navigate = useNavigate();

    const editUser = (user) => {
        localStorage.setItem("userBeingEdited", JSON.stringify(user));
        navigate("/user-edit");
    }

    const deleteUser = (user) => {
        if (window.confirm("Are you sure you want to delete " + user.email + "?")) {
            const requestOptions = {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                }
            };
            fetch(process.env.REACT_APP_API_URL + '/dedomena-user-management/users/' + user.id, requestOptions)
                .then(async response => {
                    const isJson = response.headers.get('content-type')?.includes('application/json');
                    const data = isJson && await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = (data && data.message) || response.status;
                        return Promise.reject(error);
                    } else {
                        M.toast({
                            html: '<n:i class="material-icons">check_circle</n:i> User deleted succesfully',
                            classes: 'green lighten-1 white-text'
                        });
                        props.removeUser(user);
                    }

                })
                .catch(error => {
                    console.log(error);
                    M.toast({
                        html: '<n:i class="material-icons">error</n:i> Cannot delete user. Try again later.',
                        classes: 'red lighten-1 white-text'
                    });
                });
        }
    }

    return (
        <tr className={"collection-item"}>
            <td><h5>{aUser.name} {aUser.surname} </h5></td>
            <td>
                <span className={"valign-wrapper"}><i className={"material-icons"}>email</i> {aUser.login} </span>
            </td>
            <td>
                <span className={"valign-wrapper"}><i className={"material-icons"}>phone</i> {aUser.phone} </span>
            </td>
            <td>
                <a href={"#"} onClick={() =>editUser(aUser)}><i className={"material-icons black-text"}>edit</i></a>
                <a href={"#"} onClick={() => deleteUser(aUser)}><i className={"material-icons red-text"}>delete</i></a>
            </td>
        </tr>
    )
}
export default UserListElement;