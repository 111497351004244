import L from 'leaflet'
import $ from 'jquery';
import M from "materialize-css";

L.TileLayer.BetterWMS = L.TileLayer.WMS.extend({
    onAdd: function (map) {
        // Triggered when the layer is added to a map.
        //   Register a click listener, then do all the upstream WMS things
        L.TileLayer.WMS.prototype.onAdd.call(this, map);
        map.on('click', this.getFeatureInfo, this);
    },

    onRemove: function (map) {
        // Triggered when the layer is removed from a map.
        //   Unregister a click listener, then do all the upstream WMS things
        L.TileLayer.WMS.prototype.onRemove.call(this, map);
        map.off('click', this.getFeatureInfo, this);
    },

    getFeatureInfo: function (evt) {
        // Make an AJAX request to the server and hope for the best
        var url = this.getFeatureInfoUrl(evt.latlng),
            showResults = L.Util.bind(this.showGetFeatureInfo, this);

        //console.log(url)

        // UNCOMMENT HERE AND INSTALL JQUERY

        $.ajax({
            url: url,
            crossDomain: true,
            success: function (data, status, xhr) {
                var err = typeof data === 'object' ? null : data;
                showResults(err, evt.latlng, data);
            },
            error: function (xhr, status, error) {
                //showResults(error);
            }
        });
    },

    getFeatureInfoUrl: function (latlng) {
        // Construct a GetFeatureInfo request URL given a point
        const point = this._map.latLngToContainerPoint(latlng);
        const size = this._map.getSize();

        // https://docs.geoserver.org/latest/en/user/services/wms/reference.html#wms-getfeatureinfo
        this._params = {
            request: 'GetFeatureInfo',
            service: 'WMS',
            crs: 'EPSG:4326',
            styles: this.wmsParams.styles,
            transparent: this.wmsParams.transparent,
            version: this.wmsParams.version,
            format: this.wmsParams.format,
            bbox: this._map.getBounds().toBBoxString(),
            height: size.y,
            width: size.x,
            layers: this.wmsParams.layers,
            query_layers: this.wmsParams.layers,
            info_format: 'application/json'
        };

        this._params[this._params.version === '1.3.0' ? 'i' : 'x'] = Math.floor(point.x);
        this._params[this._params.version === '1.3.0' ? 'j' : 'y'] = Math.floor(point.y);

        return this._url + L.Util.getParamString(this._params, this._url, true);
    },

    showGetFeatureInfo: function (err, latlng, jsonContent) {
        if (err) { console.log(err); return; } // do nothing if there's an error

        // Otherwise show the content in a popup, or something.

        let htmlContent = "";
        if (jsonContent != null && jsonContent.features != null && jsonContent.features.length > 0) {
            let properties = JSON.parse(JSON.stringify(jsonContent.features[0].properties));
            htmlContent += "<b>EGRIS-EGRI:</b> " + properties.egris_egri + "<br />";
            if (properties.potential_m2 !== null)
                htmlContent += "<b>Nutzungspotenzial:</b> " + Math.round(properties.potential_m2_pc*100) + "% (" + properties.potential_m2 + " m2)<br />";
            if (properties.reserve_m2_pc !== null)
                htmlContent += "<b>Ausnutzungsreserve:</b> " + Math.round(properties.reserve_m2_pc *100) + "% <br />";
            htmlContent += "<b>Parzellenfläche:</b> " + properties.flaeche+ " m2<br />";
            htmlContent += "<b>Total Volumen:</b> " + properties.total_volume+ " m3<br />";
            htmlContent += "<b>Anzahl Gebäude:</b> " + properties.buildings_count +  "<br />";

            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': localStorage.getItem('token')
                }
            };
            fetch(process.env.REACT_APP_API_URL + '/dedomena-data-management/dataset/address?dataset=' + properties.dataset + "&egris=" + properties.egris_egri, requestOptions)
                .then(async response => {
                    const isJson = response.headers.get('content-type')?.includes('application/json');
                    const data = isJson && await response.json();

                    // check for error response
                    if (!response.ok) {
                        // get error message from body or default to response status
                        const error = (data && data.message) || response.status;
                        return Promise.reject(error);
                    } else {
                        htmlContent += "<b>Adresse:</b><br /> ";
                        for (var i = 0; i < data.length; i++)
                            htmlContent += data[i] + "<br />";
                    }
                    let link = '/report?egris=' + properties.egris_egri + '&dataset=' + properties.dataset;
                    htmlContent += "<div class='row'><a class='btn red white-text right' target='_blank' href=" + link + " >report</a></div>"
                    L.popup({ maxWidth: 800})
                        .setLatLng(latlng)
                        .setContent(htmlContent)
                        .openOn(this._map);

                })
                .catch(error => {
                    console.log(error);
                });
            }
        }
});

L.TileLayer.betterWms = function (url, options) {
    return new L.TileLayer.BetterWMS(url, options);
};