import React, {useState} from "react";
import {jwtDecode} from "jwt-decode";
import logo from "../../logo.png";
import './Menu.css';
import {Link, useNavigate} from "react-router-dom";

const Menu = (props) => {
    const [token] = useState(localStorage.getItem("token"));

    const navigate = useNavigate();

    const bye = () => {
        localStorage.setItem("token", null);
        props.callBack(localStorage.getItem("token"));
        navigate("/login");
    }

    if (token === 'null' || token === '')
        bye();
    else {
        const payload = jwtDecode(token);
        if (payload.authorities === 'ROLE_ADMIN') {
            return (
                <nav className={'col s12 white'}>
                    <div className="nav-wrapper">
                        <a href="#" className="brand-logo"><img src={logo} className="Menu-logo" alt="logo"/></a>
                        <ul id="nav-mobile" className="right ">
                            <li><Link to={"/navigator"} className={"black-text"}>Navigator</Link></li>
                            <li><Link to={"/users"} className={"black-text"}>Users</Link></li>
                            <li><a className={'black-text'} onClick={bye}><i
                                className={'material-icons'}>power_settings_new</i></a></li>
                        </ul>
                    </div>
                </nav>
            );
        } else {
            return (
                <nav className={'col s12 white'}>
                    <div className="nav-wrapper">
                        <a href="#" className="brand-logo"><img src={logo} className="Menu-logo" alt="logo"/></a>
                        <ul id="nav-mobile" className="right">
                            <li><a className={'black-text'} href="/navigator">Navigator</a></li>
                            <li><a className={'black-text'} onClick={bye}><i
                                className={'material-icons'}>power_settings_new</i></a></li>
                        </ul>
                    </div>
                </nav>
            );
        }
    }
};

export default Menu;