import {Navigate} from "react-router-dom";
import {jwtDecode} from "jwt-decode";
import Menu from "../menu-component/Menu";
import "core-js/stable/atob";
import {useState} from "react";

const Dashboard = () => {
    //localStorage.removeItem("authenticated");
    const [payload, setPayload] = useState(localStorage.getItem("token"));

    if (payload === 'null' || payload == null) {
        return <Navigate replace to="/login" />;
    } else {
        const user = jwtDecode(payload);
        return (
            <div>
                <div className={'row'}>
                    <Menu callBack={setPayload} />
                </div>
                <div className={'row'}>
                    <div className={'col s12'}>
                        <p>Welcome to your Dashboard, {user.name}</p>
                    </div>
                </div>
            </div>
        );
    }
};

export default Dashboard;